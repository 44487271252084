.titleColeccion1 {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  font-family:
    Open Sans,
    sans-serif;
  letter-spacing: 1px;
  color: rgb(61, 59, 59);
}
.tabs {
  display: flex;
  justify-content: left;
  margin-block-end: 5px;
  padding-inline-end: 10px;
  position: relative;
  height: 28px;
  margin-left: 30%;
  font-size: 10px;
  font-weight: bold;
}
.tabs li {
  margin: 0;
  padding: 0 15px;
  list-style-type: none;
}
.titleColeccion2 {
  text-align: center;
  font-size: 20px;
}
@media screen and (min-width: 100px) {
  .tabs {
    display: flex;
    justify-content: left;
    margin-block-end: 5px;
    padding-inline-end: 10px;
    position: relative;
    height: 28px;
    margin-left: 1%;
    font-size: 10px;
    font-weight: bold;
  }
  .tabs li {
    margin: 0;
    padding: 0 15px;
    list-style-type: none;
  }
  .titleColeccion1 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family:
      Open Sans,
      sans-serif;
    letter-spacing: 1px;
    color: rgb(61, 59, 59);
  }
  .titleColeccion2 {
    text-align: center;
    font-size: 15px;
  }
}
@media screen and (min-width: 750px) {
  .tabs {
    display: flex;
    justify-content: left;
    margin-block-end: 5px;
    padding-inline-end: 10px;
    position: relative;
    height: 28px;
    margin-left: 10%;
    font-size: 20px;
    font-weight: bold;
  }
  .tabs li {
    margin: 0;
    padding: 0 25px;
    list-style-type: none;
  }
}
@media screen and (min-width: 830px) {
  .tabs {
    display: flex;
    justify-content: left;
    margin-block-end: 5px;
    padding-inline-end: 10px;
    position: relative;
    height: 28px;
    margin-left: 18%;
    font-size: 22px;
    font-weight: bold;
  }
  .tabs li {
    margin: 0;
    padding: 0 25px;
    list-style-type: none;
  }
}
@media screen and (min-width: 1336px) {
  .tabs {
    display: flex;
    justify-content: left;
    margin-block-end: 5px;
    padding-inline-end: 10px;
    position: relative;
    height: 28px;
    margin-left: 25%;
    font-size: 22px;
    font-weight: bold;
  }
  .tabs li {
    margin: 0;
    padding: 0 25px;
    list-style-type: none;
  }
  .titleColeccion1 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family:
      Open Sans,
      sans-serif;
    letter-spacing: 1px;
    color: rgb(61, 59, 59);
  }
  .titleColeccion2 {
    text-align: center;
    font-size: 15px;
  }
}

@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700;900&display=swap);
* {
  font-family: Open Sans, sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fafafa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ui.loading.segment:before {
  z-index: 200 !important;
}

.swal2-container {
  z-index: 1900 !important;
}

/* alert styles */
.alert-container {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;
  width: 300px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.alert-container.active {
  visibility: visible;
  opacity: 1;
  top: 50px;
  animation: slideIn 0.5s ease-out forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    top: 20px;
  }
  to {
    opacity: 1;
    top: 50px;
  }
}
.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .whatsapp-button img {
    display: initial;
    width: 100%;
    height: 100%;
  }
:root {
  --color-principal: red;
}
.MuiButton-containedPrimary {
  background-color: white;
  text-transform: none;
}

.login-desk .MuiButton-containedPrimary {
  background-color: var(--color-principal) !important;
  text-transform: none !important;
}

.login-mobile .MuiButton-containedPrimary {
  background-color: var(--color-principal) !important;
  text-transform: none !important;
  display: flex;
}
.ingre {
  margin-top: 13.3%;
}
.lo {
  display: flex;
}
/* Style The Dropdown Button */
.car {
  margin-top: 8.5%;
}
.dropbtn {
  background-color: white;
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown-container {
  display: inline-block;
}

/* The container <div> - needed to position the dropdown content */

.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */

.dropdown-content {
  display: none;
  position: fixed;
  text-align: justify !important;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  position: fixed;
  text-align: left !important;
  text-decoration: none;
  text-transform: capitalize;
  display: block;
}

/* Change color of dropdown links on hover */

.dropdown-content a:hover {
  background-color: white;
}

/* Show the dropdown menu on hover */

.dropdown:hover .dropdown-content {
  display: block;
  text-align: left !important;
}

/* Change the background color of the dropdown button when the dropdown content is shown */

.dropdown:hover .dropbtn {
  background-color: white;
  text-align: left !important;
}

/**/

/* Style The Dropdown Button */

.subdropbtn {
  background-color: white;
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.subdropdown-container {
  display: inline-block;
}

/* The container <div> - needed to position the dropdown content */

.subdropdown {
  margin-top: -4px;
  position: relative;
  /* display: inline-block; */
  width: 100%;
}

/* Dropdown Content (Hidden by Default) */

.subdropdown-content {
  display: none;
  position: absolute;
  text-align: justify !important;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */

.subdropdown-content a {
  color: black;
  padding: 12px 16px;
  position: fixed;
  text-align: left !important;
  text-decoration: none;
  text-transform: capitalize;
  display: block;
}

/* Change color of dropdown links on hover */

.subdropdown-content a:hover {
  background-color: white;
}

/* Show the dropdown menu on hover */

.subdropdown:hover .subdropdown-content {
  display: inline-grid;
  text-align: left !important;
}

/* Change the background color of the dropdown button when the dropdown content is shown */

.subdropdown:hover .subdropbtn {
  background-color: white;
  text-align: left !important;
}

.makeStyles-root-24 {
  background: var(--color-principal) !important;
}
.hed {
  width: 100%;
  text-align: center;
}
.all {
  width: 100%;
}
/* #54A59E  */
/* background: #003462; */

/* #54A59E  */
@media screen and (max-width: 500px) {
  .ingre {
    margin-left: 15%;
  }
}
@media screen and (max-width: 500px) {
  .hed {
    width: 100%;
    text-align: center;
  }
  .all {
    width: 100%;
  }
}

.select-cat {
  width: 300px;
  border: var(--color-principal) 1px solid !important;
  border-radius: 5px !important;
}

.icon {
  color: #333 !important;
}

.select-cat :nth-child(1) {
  color: black;
  font-weight: bold;
}

.linkfile {
  color: var(--color-principal);
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  text-transform: none !important;
}

.filecontainerlink {
  text-decoration: none;
  list-style: none;
}

.hprice {
  color: #a6a6a6;
  font-weight: normal;
}

.details .img-container {
  max-width: 400px;
  width: 100%;
  margin: 20px;
  height: 450px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.details .img-container:hover {
  background-size: 150%;
  border: 1px solid #ddd;
  cursor: zoom-in;
}

.img-rows {
  max-width: 400px;
  width: 100%;
  display: block;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* .box-details button,
.box-details .cart{
 
  outline: none;
  background: #54A59E;
  cursor: pointer;
  color: white;
  margin-right: 5px;
  margin-top: 10px;
} */

.box-details .cart {
  padding: 10px 25px;
  text-transform: none;
  letter-spacing: 2px;
  display: inline-block;
  border-radius: 8px;
  box-shadow: inset 1px black;
}

.counter {
  color: black !important;
  font-size: 20px !important;
  background-color: transparent !important;
}

.level {
  border-color: tomato;
  background-color: tomato;
}

.counter-co {
  margin-top: 10px;
}

.button-counter {
  background-color: var(--color-principal) !important;
  color: white !important;
  font-weight: bold;
  font-size: 17px;
  padding: 4px;
  width: 40px;
  height: 30px;
  border-color: transparent !important;
  margin-right: 0px !important;
  vertical-align: top;
  line-height: 1;
}

.number {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  text-align: center;
  top: 0;
  bottom: 0;
  width: 60px !important;
  height: 30px;
  margin-right: 5px;
  margin-left: 5px;
  color: black;
  border-width: thin;
}

.etiqueta-combo {
  color: var(--color-principal);
  font-size: 15px;
  font-weight: bold;
  border: var(--color-principal) 2px solid;
  height: 30px;
  padding: 2px;
  border-radius: 0px 0px 0px 0px;
}

.botton-detail {
  color: white !important;
  margin: 0 !important;
  opacity: 1 !important;
}

.etiqueta-text {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  color: var(--color-principal);
  font-size: 15px !important;
  font-weight: bold !important;
}

.titulo {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  color: var(--color-principal);
  font-size: 20px !important;
  font-weight: bold !important;
}

.etiqueta {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  color: black;
  font-size: 15px !important;
  font-weight: bold !important;
}

.keytext {
  color: var(--color-principal);
  font-size: 15px !important;
  font-weight: bold !important;
  margin-right: 5px !important;
}

.valtext {
  font-size: 15px !important;
  color: #a6a6a6;
  font-weight: bold !important;
}

.combo {
  margin-top: 10px;
  width: 100px;
  display: flex;
  padding: 5px;
  height: 30px;
  color: white;
  border: var(--color-principal) 2px solid;
  background-color: var(--color-principal);
  border-radius: 0px 5px 0px 0px;
}

.combo-cont {
  display: flex;
  margin-top: 10px;
}

.carrito {
  background-color: var(--color-principal);
  padding: 10px;
  color: white;
  text-transform: none !important;
  border-radius: 5px;
  margin-top: 20px;
}

.btn-cont {
  width: 150px;
}

.area {
  border: var(--color-principal) 2px solid;
  width: 100%;
  height: 200px;
  padding: 5px;
  font-size: 18px;
  color: black;
}

.tabs {
  display: inline-table;
  background-color: white;
  max-width: 1000px;
  width: 100%;
  height: 200px;
}

.tab-header {
  width: 100%;
}

.tab-chl {
  background-color: white;
  padding: 10px;
  width: 50%;

  text-align: center;
  cursor: pointer;
  border: solid 1px #ccc;
  font-weight: bold;
  font-size: 18px;
}

.react-tabs__tab--selected {
  background-color: #e7e6e6;
}

.react-tabs__tab-panel {
  margin: 20px;
}

/* * {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100%;
  margin: auto;
  box-shadow: 0 2px 6px #555;
  padding: 0 15px;
}

/* -------------------Header----------------- */

/* header{
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
} */

a {
  text-decoration: none;
  text-transform: uppercase;
  color: #555;
  letter-spacing: 2px;
}

a:hover {
  color: var(--color-principal);
}

ul li {
  text-transform: none;
  list-style: none;
  display: inline-block;
  padding: 0 15px;
}

.menu {
  display: none;
}

.logo {
  width: 100px;
}

.cart-icon {
  width: 20px;
  color: orange;
  position: relative;
  margin-right: 15px;
}

.cart-icon span {
  background: crimson;
  border-radius: 20px;
  color: white;
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 10px;
  padding: 5px;
}

/* ---------------Products--------------- */

.products {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.tittle {
  background-color: transparent;

  width: 100%;
  margin-right: 90%;
  display: flex;
  position: sticky;
}

.products .card {
  max-width: 250px;
  width: 100%;
  overflow: hidden;
  height: 570px;
  box-shadow: 0 0 5px #ccc;
  padding: 15px;
  margin: 15px;
  cursor: pointer;
}

.card img {
  width: 100%;
  height: 100%;
  max-height: 340px;
  display: block;
  object-fit: cover;
}

.card .box {
  margin: 15px 0;
}

.card .box h3 {
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card .box p {
  margin: 10px 0;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  height: 70px;
}

.card .box h4 {
  color: crimson;
}

.card .box button {
  width: 100%;
  border: none;
  outline: none;
  background: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  margin: 10px 0;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* -----------------------Details--------------- */

.details {
  margin-top: 0px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 40px;
}

.details .img-container {
  max-width: 400px;
  width: 100%;
  margin: 20px;
  height: 450px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.details .img-container:hover {
  background-size: 150%;
  border: 1px solid #ddd;
  cursor: zoom-in;
}

.box-details {
  max-width: 500px;
  width: 100%;
  margin: 20px;
}

.box-details .title {
  text-transform: none;
  color: var(--color-principal);
  letter-spacing: 2px;
  font-size: 2rem;
  font-weight: bolder;
}

.box-details .price {
  margin-top: 3px;
  text-transform: none;
  color: black;
  font-size: 20px;
  font-weight: bold !important;
}

.key-val {
  display: flex;
  padding-top: 5px;
}

.key-val2 {
  display: flex;
}

.box-details .sku {
  margin-top: 5px;
  letter-spacing: 2px;
  font-size: 15px;
  color: #a6a6a6;
  font-weight: bold !important;
}

/* .box-details button,
.box-details .cart{
  border: 1px solid #555;
  outline: none;
  background: #333;
  cursor: pointer;
  color: white;
  margin-right: 5px;
  margin-top: 10px;
} */

.colors button,
.sizes button {
  width: 35px;
  height: 35px;
}

.box-details p {
  line-height: 1.5;
  margin: 10px 0;
  opacity: 0.8;
}

.bpr {
  margin-left: 91%;
  margin-top: -4%;
}

.b {
  margin-left: 6%;
}

.comenta {
  margin-left: 2%;
}

.clist {
  margin-left: 0%;
}

.plist {
  margin-left: -6%;
}

.pre {
  margin-left: 100%;
}

.box-details h3 {
  color: rgb(24, 22, 22);
}

.box-details .thumb {
  display: flex;
  cursor: pointer;
}

.box-details .thumb img {
  width: 50px;
  height: 45px;
  display: block;
  object-fit: cover;
  margin-right: 20px;
  overflow: hidden;
}

.box-details .cart {
  padding: 10px 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: inline-block;
}

/* @media (max-width: 700px){
  header{
    justify-content: space-between;
  }
  ul{
    position: fixed;
    top:0;
    left: -100%;
    width: 100%;
    height: 100vh;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    opacity: 0.98;
    z-index: 99;
    transition: 0.5s ease-in;
  }
  .menu{
    display: block;
    cursor: pointer;
  }
  ul li .menu{
    position: absolute;
    top: 20px;
    right: 20px;
  }
} */
@media screen and (max-width: 500px) {
  .tittle {
    background-color: transparent;

    width: 100%;
    margin-right: 60%;
    display: flex;
    position: sticky;
  }

  .tab-chl {
    padding: 10px;
    width: 90%;
    margin-left: 5%;
    text-align: center;
    cursor: pointer;
    border: solid 1px #ccc;
    font-weight: bold;
    font-size: 18px;
  }

  .t {
    margin-left: 0%;
    width: 100%;
    text-align: justify;
  }

  .clist {
    margin-left: 0%;
  }
}

@media (max-width: 500px) {
  .tittle {
    background-color: transparent;

    width: 100%;
    margin-right: 60%;
    display: flex;
    position: sticky;
  }

  .box-details .thumb img {
    width: 40px;
    height: 35px;
  }

  .details .img-container {
    height: 350px;
  }
}

/* ****** Cart ********************** */

.cart {
  position: relative;
  border: 1px solid #ccc;
  transform: scaleY(0.98);
}

.amount span {
  color: crimson;
  padding: 0 20px;
}

.amount .count {
  width: 40px;
  height: 30px;
  border: 1px solid #555;
  outline: none;
  background: #555;
  cursor: pointer;
}

.delete {
  position: absolute;
  top: 0;
  right: 5px;
  color: crimson;
  cursor: pointer;
  font-weight: 900;
}

.total {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total a {
  background: #333;
  color: white;
  padding: 10px 25px;
}

.total h3 {
  margin-right: 50px;
  color: crimson;
}

.tabs1 {
  margin-left: 5%;
  margin-right: 5%;
}

@media screen and (min-width: 100px) {
  .question {
    width: 70%;
    margin-top: 10%;
    /* margin: 0; */
    margin-left: 20%;
  }

  .colec {
    margin-left: 20%;
  }
}

@media screen and (min-width: 1336px) {
  .tabs1 {
    width: 70%;
    /* display: flex; */
    justify-content: left;
    margin-block-end: 5px;
    padding-inline-end: 10px;
    position: relative;
    height: 28px;
    margin-left: 5%;
    font-size: 22px;
    font-weight: bold;
    margin-top: 0%;
  }

  .colec {
    margin-left: 45%;
  }

  .question {
    width: 60%;
    margin-top: 30%;
    /* margin: 0; */
    margin-left: 20%;
  }

  .tabs li {
    margin: 0;
    padding: 0 25px;
    list-style-type: none;
  }

  .titleColeccion1 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family:
      Open Sans,
      sans-serif;
    letter-spacing: 1px;
    color: rgb(61, 59, 59);
  }

  .titleColeccion2 {
    text-align: center;
    font-size: 15px;
  }
}
.iconColor {
  color: var(--color-principal) !important;
}

.close-icon {
  text-decoration: none !important;
  list-style: none;
  color: black;
  cursor: pointer;
}
.menu-barscloseHead {
  text-decoration: none !important;
}
.a {
  text-decoration: none !important;
}

.menu-barscloseHead {
  width: 50%;
  text-align: end;
  color: rgb(212, 131, 131);
}

.cart-head {
  display: flex;
}

.title-head {
  width: 50%;
}
.MuiSvgIcon-root {
  fill: var(--color-principal) !important;
}
.MuiSvgIcon-navBar {
  fill: #ffffff !important;
}

.mobile-carousel .MuiSvgIcon-root {
  fill: white !important;
}

.mobile-carousel .MuiButtonBase-root {
  background-color: var(--color-principal) !important;
}
.card-cart-counter {
  margin-top: 10px;
  margin-bottom: 10px;
}
.gridAlign {
  text-align: right;
}
.nrow {
  display: flex;
  padding: 5px;
}
.separator {
  margin: 0px;
}
.line {
  margin: 30px;
  height: 1px;
}
.marginRow {
  margin-left: 90px;

  margin-right: 90px;
}
.marginGrid {
  float: right !important;
}
.marginBottom {
  margin: 0px, 20px, 0px, 20px;
}
.margin50 {
  margin-left: 5px;
  margin-right: 50px;
}
.marginImg {
  margin-left: 5px;
  margin-right: 30px;
}
.margin70 {
  margin-left: 70px;
  margin-right: 30px;
}
.marginCar {
  margin-left: 30px;
  margin-bottom: 10px;
  margin-top: 0px;
}
.marginIcon {
  margin-left: 10px;
  margin-top: 10px;
}
.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
}

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
}
.marginHeader {
  margin-top: 10%;
}
.text-bold {
  font-weight: bold !important;
}
button:disabled,
button[disabled] {
  background-color: #003462ab !important;
  cursor: no-drop;
}

.principalMenu{
    color: var(--color-principal) !important;
}
.react-search-field {
  width: 40%;
  border: #3f51b5 1px solid !important;
  border-radius: 10px;
}
.login-mobile {
  width: 100%;
}

.login-mobile .MuiButtonGroup-grouped {
  margin-left: auto;
  margin-right: 5%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.cart-mobile {
  margin-left: auto;
  margin-right: 0;
}

.cart-mobile .MuiBox-root {
  margin-right: 0px !important;
}

.react-search-field-input {
  background-color: transparent;
  color: #3f51b5 !important;
  font-weight: normal !important;
}

.react-search-field-input::placeholder {
  color: #3f51b5 !important;
}

.react-search-field-button {
  background-color: transparent !important;
  border-left: transparent 1px solid !important;
}

.MuiButton-containedPrimary {
  background-color: var(--color-principal) !important;
  text-transform: none !important;
}

.react-search-field-button :first-child {
  fill: #3f51b5;
}

@media only screen and (max-width: 900px) {
  .react-search-field {
    width: 90%;
    margin-left: 5%;
  }
}

.banner {
  display: flex;
}
.ofer {
  display: flex;
  margin-left: 5%;
}
.mydat {
  margin-left: 7%;
  color: red;
}
.offerclock {
  border: 1px solid rgb(155, 5, 5);
  border-radius: 5px;
  position: absolute;
  right: 110px;
  top: 0px;
}
.stars {
  margin-left: 36%;
  margin-top: -5%;
}
.priceA {
  color: rgb(2, 2, 2);
  /* display: block; */
  text-decoration: none;
  text-align: left;
  margin-left: 40%;
  width: 100%;
  letter-spacing: 1px;
  height: 30%;
  font-size: 70%;
  font-family:
    Open Sans,
    sans-serif;
}
.contenido {
  margin-top: -12%;
}
.descripcion {
  color: rgb(2, 2, 2);
  /* display: block; */
  text-decoration: none;
  text-align: left;
  margin-left: 40%;
  width: 60%;
  letter-spacing: 1px;
  height: 30%;
  font-size: 70%;
  font-family:
    Open Sans,
    sans-serif;
}
.price {
  color: rgb(2, 2, 2);
  /* display: block; */
  text-decoration: none;
  text-align: left;
  margin-left: 40%;
  width: 100%;
  letter-spacing: 1px;
  height: 30%;
  font-size: 70%;
  font-weight: bold;
  font-family:
    Open Sans,
    sans-serif;
}
.name {
  color: rgb(2, 2, 2);
  display: block;
  text-decoration: none;
  text-align: left;
  padding-left: 5%;
  width: 100%;
  letter-spacing: 1px;
  height: 30%;
  font-size: 70%;
  font-family:
    Open Sans,
    sans-serif;
}
.ofertaimg {
  width: 6%;
  height: 50%;
  margin-top: 0%;
  margin-left: 10%;
}
.letras {
  margin-bottom: 12px;
  margin-top: -27%;

  font-weight: 600;
  color: #4b4b4b;
  width: 30%;
  line-height: 1;
}
.dentro {
  position: relative;
  display: inline-block;
}
.suscribe {
  display: flex;
  margin: auto;
}
.parrafos {
  width: 50%;
  font-size: 26px;
  margin-left: 60%;
}
.parrafoImagen {
  width: 70%;
}
.titulosList {
  text-align: center;
}
.parrafosTitle {
  font-size: 25px;
  margin-left: 60%;
}
.back {
  margin-left: 0%;
}
.contenedorInf {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 100%;
}
.centradoInf {
  position: absolute;
  top: 90%;
  left: 50%;
  color: rgb(250, 250, 250);
  font-style: normal;
  font-size: 30px;
  transform: translate(-50%, -50%);
}
.parrafosFecha {
  font-size: 25px;
  margin-left: 60%;
  color: var(--color-principal);
}
.boxparrafo {
  float: left;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 5px;
}
.img0 {
  filter: var(--colorima0);
}
.img1 {
  filter: var(--colorima1);
}
.img2 {
  filter: var(--colorima2);
}
.img3 {
  filter: var(--colorima3);
}
.img4 {
  filter: var(--colorima4);
}
.img5 {
  filter: var(--colorima5);
}
.img6 {
  filter: var(--colorima6);
}
.img7 {
  filter: var(--colorima7);
}
.img8 {
  filter: var(--colorima8);
}
.img9 {
  filter: var(--colorima9);
}
.img10 {
  filter: var(--colorima10);
}
a:hover {
  color: #1a1d1b;
  cursor: pointer;
}
.a {
  text-decoration: none !important;
}
.img11 {
  filter: var(--colorima11);
}
.img12 {
  filter: var(--colorima12);
}
.img13 {
  filter: var(--colorima13);
}
.img14 {
  filter: var(--colorima14);
}
.img15 {
  filter: var(--colorima15);
}
.img16 {
  filter: var(--colorima16);
}
.img17 {
  filter: var(--colorima17);
}
.img18 {
  filter: var(--colorima18);
}
.img19 {
  filter: var(--colorima19);
}
.img20 {
  filter: var(--colorima20);
}
.img21 {
  filter: var(--colorima21);
}
.img22 {
  filter: var(--colorima22);
}
.img23 {
  filter: var(--colorima23);
}
.img24 {
  filter: var(--colorima24);
}
.img25 {
  filter: var(--colorima25);
}
.img26 {
  filter: var(--colorima26);
}
.img27 {
  filter: var(--colorima27);
}
.img28 {
  filter: var(--colorima28);
}
.img29 {
  filter: var(--colorima29);
}
.img30 {
  filter: var(--colorima30);
}
.img31 {
  filter: var(--colorima31);
}
.box {
  background: #e9e6e6;
}
.ver {
  display: flex;
  margin-left: 0%;
}
.imagen {
  width: 40%;
  height: 70%;
}
.imagenes {
  width: 32%;
  height: 20%;
  margin-top: 0%;
}
.letra {
  margin-bottom: 12px;
  margin-top: -27%;

  font-weight: 600;
  color: #4b4b4b;
  width: 30%;
  line-height: 1;
}
.tam {
  width: 100%;
  height: 20%;
  margin: 15px;
}
.but {
  margin-left: 0%;
}
.subt {
  font-size: 200%;
  width: 65%;
}
.alt {
  margin-top: 0%;
}
.letra {
  text-align: center;
}
.imagenes {
  width: 28%;
  height: 20%;
  margin-left: 50%;
}
.content {
  margin: auto;
}
.caru {
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  border-color: rgb(223, 220, 220);
}
.myimg {
  width: 100%;
}

.mytitulo {
  color: rgb(2, 2, 2);
  /* display: block; */
  text-decoration: none;
  text-align: left;

  letter-spacing: 1px;
  width: 10%;

  font-size: 24px;

  font-family:
    Open Sans,
    sans-serif;
}
.myfecha {
  color: var(--color-principal);
  /* display: block; */
  text-decoration: none;

  font-weight: bold;
  margin-left: 32%;
  letter-spacing: 1px;

  font-size: 24px;
  font-family:
    Open Sans,
    sans-serif;
}
.caru {
  width: 100%;
  height: 98%;

  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  border-color: rgb(223, 220, 220);
}

.caru2 {
  padding-left: 5%;
  padding-right: 5%;
}
.titleColeccion1 {
  text-align: center;
  font-weight: bold;
  font-size: 27px;
  font-family:
    Open Sans,
    sans-serif;
  letter-spacing: 1px;
  color: rgb(61, 59, 59);
}
.carus {
  width: 95%;
  height: 98%;

  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  border-color: rgb(223, 220, 220);
}
@media screen and (min-width: 100px) {
  .centradoInf {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 60%;
    color: rgb(250, 250, 250);
    font-style: normal;
    font-size: 30px;
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: 300px) {
  .alt {
    margin-top: -1%;
    margin-left: 5%;
  }
  .letra {
    margin-bottom: 12px;
    margin-top: -25%;
    font-size: 2px !important;
    font-weight: 600;
    color: #4b4b4b;
    width: 30%;
    height: 100%;
    line-height: 1;
  }
  .titleColeccion1 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family:
      Open Sans,
      sans-serif;
    letter-spacing: 1px;
    color: rgb(61, 59, 59);
  }
  .subt {
    font-size: 180%;
    margin-top: -1%;
    display: block;
    width: 100%;
    margin-left: 30%;
  }
  .subt2 {
    font-size: 95%;
    margin-top: 5%;
    display: block;
    margin-left: 30%;
  }
  .ver {
    display: block;
    margin-left: 2%;
  }
  .but {
    margin-left: 3%;
  }
  .tam {
    width: 90%;
    height: 100%;
  }
  .imagenes {
    width: 28%;
    height: 20%;
    margin-left: 30%;
  }
}
@media screen and (min-width: 768px) {
  .carus {
    width: 100%;
    height: 98%;

    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }
  .letras {
    margin-bottom: 12px;
    margin-top: -27%;

    font-weight: 600;
    color: #4b4b4b;
    width: 30%;
    line-height: 1;
  }
  .stars {
    margin-left: 39%;
    margin-top: -2%;
  }
  .offerclock {
    border: 1px solid rgb(155, 5, 5);
    border-radius: 5px;
    position: absolute;
    right: 110px;
    top: 0px;
  }
  .ofertaimg {
    width: 20%;
    height: 25%;
    margin-left: 5%;
    margin-top: auto;
  }
  .contenido {
    margin-top: -20%;
  }
  .name {
    color: rgb(2, 2, 2);
    display: block;
    text-decoration: none;
    text-align: left;
    margin-left: 34%;
    width: 70%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 15px;
    font-family:
      Open Sans,
      sans-serif;
  }
  .descripcion {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    margin-left: 40%;
    width: 50%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 14px;
    font-family:
      Open Sans,
      sans-serif;
  }
  .price {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    margin-left: 40%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 12px;
    font-weight: bold;
    font-family:
      Open Sans,
      sans-serif;
  }
  .priceA {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    margin-left: 40%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 12px;

    font-family:
      Open Sans,
      sans-serif;
  }
  .contenedorInf {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 100%;
  }
  .centradoInf {
    display: flex;
    width: 90%;
    margin-top: 0%;
  }
  .back {
    margin-left: 17%;
    font-size: 14px;
  }
  .parrafos {
    width: 70%;
    font-size: 18px;
    margin-left: 17%;
    margin-top: 0%;
  }
  .parrafosTitle {
    font-weight: bold;

    font-size: 22px;
    margin-top: 0%;

    margin-left: 18%;
  }
  .a {
    text-decoration: none !important;
  }
  .parrafosFecha {
    font-size: 23px;
    margin-left: 17%;
    margin-top: 0%;

    color: var(--color-principal);
  }
  .parrafoImagen {
    width: 70%;
    margin-left: 17%;
    margin-top: 0%;
  }
  .boxparrafo {
    border-width: 1px;
    width: 102%;
    border-style: solid;
    border-radius: 5px;
    border-color: rgb(180, 178, 178);
    margin-left: -1%;
    margin-top: 0%;
  }
  .titulosList {
    font-size: 20px;
    color: var(--color-principal);
    text-align: left;
  }
  a:hover {
    color: #1a1d1b;
    cursor: pointer;
  }
  .textoparraf {
    margin-top: 6%;
    visibility: hidden;
    font-size: 17px;

    margin-left: 2%;

    background-color: rgb(209, 207, 207);
    width: 20%;
    border-style: solid;
    border-radius: 5px;
    border-color: rgb(209, 207, 207);
  }
  .banner {
    display: block;
    margin-right: 30%;
    text-align: center !important;
  }
  .alt {
    margin-top: -12%;
    margin-left: -3%;
  }
  .letra {
    margin-bottom: 12px;
    margin-top: -25%;
    font-size: 2px !important;
    font-weight: 600;
    color: #4b4b4b;
    width: 30%;
    height: 100%;
    line-height: 1;
  }
  .titleColeccion1 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family:
      Open Sans,
      sans-serif;
    letter-spacing: 1px;
    color: rgb(61, 59, 59);
  }
  .subt {
    font-size: 180%;
    margin-top: -1%;
    display: block;
    width: 190%;
    margin-left: 30%;
  }
  .subt2 {
    font-size: 95%;
    margin-top: 5%;
    display: block;
    margin-left: 30%;
  }
  .ver {
    display: block;
    margin-left: 2%;
  }
  .but {
    margin-left: 3%;
  }
  .tam {
    width: 90%;
    height: 100%;
  }
  .imagenes {
    width: 28%;
    height: 20%;
    margin-left: 50%;
  }
  .content {
    margin: auto;
  }
  .caru {
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }
  .myimg {
    width: 100%;
  }
}
.mytitulo {
  color: rgb(2, 2, 2);
  /* display: block; */
  text-decoration: none;
  text-align: left;

  letter-spacing: 1px;
  width: 10%;

  font-size: 14px;

  font-family:
    Open Sans,
    sans-serif;
}
.myfecha {
  color: var(--color-principal);
  /* display: block; */
  text-decoration: none;

  font-weight: bold;
  margin-left: 25%;
  letter-spacing: 1px;

  font-size: 14px;
  font-family:
    Open Sans,
    sans-serif;
}
.caru {
  width: 100%;
  height: 98%;

  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  border-color: rgb(223, 220, 220);
}

.caru2 {
  padding-left: 5%;
  padding-right: 5%;
}
@media screen and (min-width: 1200px) {
  .alt {
    margin-top: -5%;
    margin-left: 2%;
  }
  .letras {
    color: #4b4b4b;
    width: 30%;
  }
  .subt2 {
    font-size: 95%;
    margin-top: 25%;
    display: block;
    margin-left: 50%;
  }
  .subt {
    font-size: 180%;
    margin-top: -5%;
    display: block;
    width: 100%;
    margin-left: 50%;
  }
}
@media screen and (min-width: 830px) {
  .carus {
    width: 100%;
    height: 98%;

    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }
  .letras {
    margin-bottom: 12px;
    margin-top: -27%;

    font-weight: 600;
    color: #4b4b4b;
    width: 30%;
    line-height: 1;
  }
  .stars {
    margin-left: 39%;
  }
  .offerclock {
    border: 1px solid rgb(155, 5, 5);
    border-radius: 5px;
    position: absolute;
    right: 110px;
    top: 0px;
  }
  .ofertaimg {
    width: 15%;
    height: 25%;
    margin-left: 5%;
    margin-top: auto;
  }
  .contenido {
    margin-top: -15%;
  }
  .name {
    color: rgb(2, 2, 2);
    display: block;
    text-decoration: none;
    text-align: left;
    margin-left: 34%;
    width: 70%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 15px;
    font-family:
      Open Sans,
      sans-serif;
  }
  .descripcion {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    margin-left: 40%;
    width: 50%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 14px;
    font-family:
      Open Sans,
      sans-serif;
  }
  .price {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    margin-left: 40%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 12px;
    font-weight: bold;
    font-family:
      Open Sans,
      sans-serif;
  }
  .priceA {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    margin-left: 40%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 12px;

    font-family:
      Open Sans,
      sans-serif;
  }
  .contenedorInf {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 100%;
  }
  .centradoInf {
    display: flex;
    width: 90%;
    margin-top: 0%;
  }
  .back {
    margin-left: 17%;
    font-size: 14px;
  }
  .parrafos {
    width: 70%;
    font-size: 18px;
    margin-left: 17%;
    margin-top: 0%;
  }
  .parrafosTitle {
    font-weight: bold;

    font-size: 22px;
    margin-top: 0%;

    margin-left: 18%;
  }
  .a {
    text-decoration: none !important;
  }
  .parrafosFecha {
    font-size: 23px;
    margin-left: 17%;
    margin-top: 0%;

    color: var(--color-principal);
  }
  .parrafoImagen {
    width: 70%;
    margin-left: 17%;
    margin-top: 0%;
  }
  .boxparrafo {
    border-width: 1px;
    width: 102%;
    border-style: solid;
    border-radius: 5px;
    border-color: rgb(180, 178, 178);
    margin-left: -1%;
    margin-top: 0%;
  }
  .titulosList {
    font-size: 20px;
    color: var(--color-principal);
    text-align: left;
  }
  a:hover {
    color: #1a1d1b;
    cursor: pointer;
  }
  .textoparraf {
    margin-top: 6%;
    visibility: hidden;
    font-size: 17px;

    margin-left: 2%;

    background-color: rgb(209, 207, 207);
    width: 20%;
    border-style: solid;
    border-radius: 5px;
    border-color: rgb(209, 207, 207);
  }
  .banner {
    display: block;
    margin-right: 30%;
    text-align: center !important;
  }
  .alt {
    margin-top: -4%;
    margin-left: -3%;
  }
  .letra {
    margin-bottom: 12px;
    margin-top: -25%;
    font-size: 2px !important;
    font-weight: 600;
    color: #4b4b4b;
    width: 30%;
    height: 100%;
    line-height: 1;
  }
  .titleColeccion1 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family:
      Open Sans,
      sans-serif;
    letter-spacing: 1px;
    color: rgb(61, 59, 59);
  }
  .subt {
    font-size: 180%;
    margin-top: -1%;
    display: block;
    width: 80%;
    margin-left: 30%;
  }
  .subt2 {
    font-size: 95%;
    margin-top: 5%;
    display: block;
    margin-left: 30%;
  }
  .ver {
    display: flex;
    margin-left: 2%;
  }
  .but {
    margin-left: 3%;
  }
  .tam {
    width: 90%;
    height: 100%;
  }
  .imagenes {
    width: 28%;
    height: 20%;
    margin-left: 30%;
  }
  .content {
    margin: auto;
  }
  .caru {
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }
  .myimg {
    width: 100%;
  }
}
.mytitulo {
  color: rgb(2, 2, 2);
  /* display: block; */
  text-decoration: none;
  text-align: left;

  letter-spacing: 1px;
  width: 10%;

  font-size: 14px;

  font-family:
    Open Sans,
    sans-serif;
}
.myfecha {
  color: var(--color-principal);
  /* display: block; */
  text-decoration: none;

  font-weight: bold;
  margin-left: 25%;
  letter-spacing: 1px;

  font-size: 14px;
  font-family:
    Open Sans,
    sans-serif;
}
.caru {
  width: 100%;
  height: 98%;

  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  border-color: rgb(223, 220, 220);
}

.caru2 {
  padding-left: 5%;
  padding-right: 5%;
}
@media screen and (min-width: 1336px) {
  .alt {
    margin-top: -8%;
    margin-left: 0%;
  }
  .imagenes {
    width: 28%;
    height: 20%;
    margin-left: 40%;
  }
  .letras {
    color: #4b4b4b;
    width: 30%;
  }
  .ver {
    display: flex;
    margin-left: 2%;
  }
  .subt2 {
    font-size: 95%;
    margin-top: 5%;
    display: block;
    margin-left: 30%;
  }
  .subt {
    font-size: 180%;
    margin-top: -1%;
    display: block;
    width: 100%;
    margin-left: 30%;
  }
  .mydat {
    font-size: 22px;
    color: red;
  }
  .stars {
    margin-left: 40%;
  }
  .offerclock {
    font-size: 20px;
    color: red;
  }
  .ofertaimg {
    width: 25%;
    height: 35%;
    margin-left: 5%;
    margin-top: auto;
  }
  .contenido {
    margin-top: -10%;
  }
  .content {
    margin: auto;
  }
  .caru {
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }
  .myimg {
    width: 100%;
  }
  .ofer {
    display: flex;
    margin-left: 45%;
  }
  .mytitulo {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;

    letter-spacing: 1px;
    width: 10%;

    font-size: 24px;

    font-family:
      Open Sans,
      sans-serif;
  }
  .myfecha {
    color: var(--color-principal);
    /* display: block; */
    text-decoration: none;

    font-weight: bold;
    margin-left: 32%;
    letter-spacing: 1px;

    font-size: 24px;
    font-family:
      Open Sans,
      sans-serif;
  }
  .carus {
    width: 95%;
    height: 98%;

    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }
  .caru {
    width: 90%;
    height: 250px;
    margin-left: 3%;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }

  .caru2 {
    padding-left: 5%;
    padding-right: 5%;
  }
  .titleColeccion1 {
    text-align: center;
    font-weight: bold;
    font-size: 27px;
    font-family:
      Open Sans,
      sans-serif;
    letter-spacing: 1px;
    color: rgb(61, 59, 59);
  }
  .parrafos {
    width: 70%;
    font-size: 20px;
    margin-left: 18%;
    margin-top: 0%;
  }
  .parrafosTitle {
    font-weight: bold;

    font-size: 25px;
    margin-top: 0%;

    margin-left: 18%;
  }

  .a {
    text-decoration: none !important;
  }
  .back {
    margin-left: 18%;
    font-size: 20px;
  }
  .parrafosFecha {
    font-size: 25px;
    margin-left: 17%;
    margin-top: 0%;

    color: var(--color-principal);
  }
  .parrafoImagen {
    width: 70%;
    margin-left: 17%;
    margin-top: 0%;
  }
  .boxparrafo {
    border-width: 1px;
    width: 102%;
    border-style: solid;
    border-radius: 5px;
    border-color: rgb(180, 178, 178);
    margin-left: -1%;
    margin-top: 0%;
  }
  .titulosList {
    font-size: 20px;
    color: var(--color-principal);
    text-align: left;
  }
  a:hover {
    color: #1a1d1b;
    cursor: pointer;
  }
  .textoparraf {
    margin-top: 6%;

    font-size: 17px;

    margin-left: 2%;

    background-color: rgb(209, 207, 207);
    width: 20%;
    border-style: solid;
    border-radius: 5px;
    border-color: rgb(209, 207, 207);
  }
  .contenedorInf {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 100%;
  }
  .centradoInf {
    display: flex;
    width: 50%;
    margin-top: -3%;
  }
  .name {
    color: rgb(2, 2, 2);
    display: block;
    text-decoration: none;
    text-align: left;
    margin-left: 35%;
    width: 70%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 20px;
    font-family:
      Open Sans,
      sans-serif;
  }
  .descripcion {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    margin-left: 40%;
    width: 60%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 18px;
    font-family:
      Open Sans,
      sans-serif;
  }
  .price {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    margin-left: 40%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 18px;
    font-weight: bold;
    font-family:
      Open Sans,
      sans-serif;
  }
  .priceA {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    margin-left: 40%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 15px;

    font-family:
      Open Sans,
      sans-serif;
  }
  .suscribe {
    display: flex;
    background-color: #fff;

    margin-top: -15%;
  }
  .dentro {
    height: 100%;
    width: 100%;
  }
}

.desk-banner {
  margin-top: 10px;
  width: 100%;
  height: 100%;
  color: black;
}

.carousel .control-dots .dot {
  border-radius: 0px !important;
}

.carousel.carousel-slider .control-arrow {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 !important;
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  bottom: auto !important;
  opacity: 1 !important;
  background-color: var(--color-principal) !important;
}

.carousel.carousel-slider .control-arrow :hover {
  background-color: var(--color-principal) !important;
  filter: none !important;
}

.Banner {
  height: 400px;
  position: relative;
}

.Banner .Media {
  background-color: rgb(236, 245, 240);
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: 300ms;
  cursor: pointer;
}

.Banner .Media .MediaCaption {
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0;
  padding: 15px;
  background-color: black;
  color: white;
  opacity: 0.6;
  width: 100%;
  height: 10%;
  font-weight: 200;
  transition: 300ms;
  cursor: pointer;
}

.Banner .Media .MediaCaption:hover {
  opacity: 0.8;
}

.Banner .Media:hover {
  filter: brightness(115%);
}

.Banner .BannerGrid {
  height: 100%;
  position: relative;
}

.Banner .Content {
  color: white;
  background-color: blue;
  height: 100%;
  position: relative;
  cursor: pointer;
  padding: 30px;
  transition: 300ms;
}

.Banner .Content:hover,
.Banner .Content:active {
  background-color: red-active;
}

.Banner .Content .Title {
  font-weight: 500;
}

.Banner .Content .Caption {
  margin-top: 10px;
}

.Banner .Content .ViewButton {
  margin-top: 40px;
  color: white;
  border: 3px solid white;
  text-transform: capitalize;
  transition: 200ms;
}

.contenedor {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 100%;
}
.contenedor2 {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 100%;
  zoom: 200%;
}

.contenedor3 {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 100%;
  zoom: 200%;
}
.texto-encima {
  position: absolute;
  top: 10px;
  left: 10px;
}

.centrado {
  position: absolute;
  top: 90%;
  left: 50%;
  color: rgb(250, 250, 250);
  font-style: normal;
  font-size: 30px;
  transform: translate(-50%, -50%);
}
.centrado2 {
  position: absolute;
  top: 90%;
  left: 50%;
  color: rgb(250, 250, 250);
  font-style: normal;
  font-size: 12px;
  transform: translate(-50%, -50%);
}
.centrado3 {
  position: absolute;
  top: 90%;
  left: 50%;
  color: rgb(250, 250, 250);
  font-style: normal;
  font-size: 12px;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 500px) {
  .centrado {
    position: absolute;
    top: 90%;
    left: 50%;
    color: rgb(250, 250, 250);
    font-style: normal;
    font-size: 10px;
    transform: translate(-50%, -50%);
  }
  .centrado2 {
    position: absolute;
    top: 90%;
    left: 50%;
    color: rgb(250, 250, 250);
    font-style: normal;
    font-size: 10px;
    transform: translate(-50%, -50%);
  }
  .centrado3 {
    position: absolute;
    top: 90%;
    left: 50%;
    color: rgb(250, 250, 250);
    font-style: normal;
    font-size: 10px;
    transform: translate(-50%, -50%);
  }
}

.titleColeccion1 {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  font-family:
    Open Sans,
    sans-serif;
  letter-spacing: 1px;
  color: rgb(61, 59, 59);
}
.tabs {
  display: flex;
  justify-content: left;
  margin-block-end: 5px;
  padding-inline-end: 10px;
  position: relative;
  height: 28px;
  margin-left: 30%;
  font-size: 10px;
  font-weight: bold;
}
.tabs li {
  margin: 0;
  padding: 0 15px;
  list-style-type: none;
}
.titleColeccion2 {
  text-align: center;
  font-size: 20px;
}
@media screen and (min-width: 100px) {
  .tabs {
    display: flex;
    justify-content: left;
    margin-block-end: 5px;
    padding-inline-end: 10px;
    position: relative;
    height: 28px;
    margin-left: 1%;
    font-size: 10px;
    font-weight: bold;
  }
  .tabs li {
    margin: 0;
    padding: 0 15px;
    list-style-type: none;
  }
  .titleColeccion1 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family:
      Open Sans,
      sans-serif;
    letter-spacing: 1px;
    color: rgb(61, 59, 59);
  }
  .titleColeccion2 {
    text-align: center;
    font-size: 15px;
  }
}
@media screen and (min-width: 750px) {
  .tabs {
    display: flex;
    justify-content: left;
    margin-block-end: 5px;
    padding-inline-end: 10px;
    position: relative;
    height: 28px;
    margin-left: 10%;
    font-size: 20px;
    font-weight: bold;
  }
  .tabs li {
    margin: 0;
    padding: 0 25px;
    list-style-type: none;
  }
}
@media screen and (min-width: 830px) {
  .tabs {
    display: flex;
    justify-content: left;
    margin-block-end: 5px;
    padding-inline-end: 10px;
    position: relative;
    height: 28px;
    margin-left: 18%;
    font-size: 22px;
    font-weight: bold;
  }
  .tabs li {
    margin: 0;
    padding: 0 25px;
    list-style-type: none;
  }
}
@media screen and (min-width: 1336px) {
  .tabs {
    display: flex;
    justify-content: left;
    margin-block-end: 5px;
    padding-inline-end: 10px;
    position: relative;
    height: 28px;
    margin-left: 25%;
    font-size: 22px;
    font-weight: bold;
  }
  .tabs li {
    margin: 0;
    padding: 0 25px;
    list-style-type: none;
  }
  .titleColeccion1 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family:
      Open Sans,
      sans-serif;
    letter-spacing: 1px;
    color: rgb(61, 59, 59);
  }
  .titleColeccion2 {
    text-align: center;
    font-size: 15px;
  }
}

/* Card */

/*@import url("../../../Views/Colores.js");*/
.fa,
.fas {
  color: orange;
  font-weight: 900;
}

.spa {
  margin-top: 5%;
}

.space {
  margin-top: 5%;
}

.product4 {
  margin-top: 0%;
}

.myact {
  color: #b11313;
  width: 20px;
  height: 20px;
}

.active2 {
  width: 20px;
  height: 20px;
  color: #069435;
}

.add {
  margin-left: 1%;
  margin-top: 2%;
}

.addMob {
  margin-left: 1%;
  margin-top: 2%;
}

.bucart {
  display: flex;
}

.bucartMob {
  margin-top: 0%;
  margin-left: 10%;
  display: flex;
}

.icon {
  width: 20px;
  height: 20px;
}

.add {
  margin-left: 1%;
  margin-top: 2%;
}

.addMob {
  margin-left: 1%;
  margin-top: 2%;
}

.nolink {
  text-decoration: none;
  text-transform: none;
}

.variant {
  margin-top: -8%;
  display: flex;
  padding-left: 8%;
}

.variant2 {
  margin-top: -8%;
  display: flex;
  padding-left: 8%;
}

.anterior {
  margin-right: 10%;
  width: 100%;
}

.preciomobt {
  margin-right: 10%;
  width: 100%;
}

.product3 {
  margin-top: 13%;
}

.fou {
  color: #fff;
  font-size: 10px;
}

.pdnames {
  color: rgb(2, 2, 2);
  /* display: block; */
  text-decoration: none;
  text-align: left;
  padding-left: 5%;
  width: 100%;
  letter-spacing: 1px;
  height: 30%;
  font-size: 24px;
  font-family:
    Open Sans,
    sans-serif;
  margin-top: 0%;
}

.esp {
  margin-top: -60%;
}

.side-bar .side-box {
  float: left;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 5px;
}

.side-bar .box-heading {
  color: #000;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  margin-block: 5px;
  text-transform: capitalize;
  padding: 10px 15px;
  position: relative;
  margin-block-start: 0;
  background-color: rgb(228, 220, 220);
  letter-spacing: 0.2px;
}

.side-bar .box-content {
  float: left;
  width: 100%;
  position: relative;
  margin: 5px 0 0;
  padding: 5px 20px 10px;
}

.tag {
  background-color: #ce4040;
  width: 15%;
  height: 4%;
}

.tag2 {
  background-color: #ce4040;
  width: 15%;
  margin-top: 2%;
}

.tagmob {
  margin-top: 75%;
}

.coleccion {
  width: 100%;
  height: 70%;
  margin-top: 7%;
  margin: 0 auto;
}

.coleccionMobile {
  width: 100%;
  height: 100%;
  margin-top: 5%;
  padding-top: 50%;
}

:root {
  --color-principal: white;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.product-namemob {
  color: rgb(2, 2, 2);
  /* display: block; */
  text-decoration: none;
  text-align: left;
  padding-left: 5%;
  width: 100%;
  letter-spacing: 1px;
  height: 30%;
  font-size: 24px;
  font-family:
    Open Sans,
    sans-serif;
  margin-top: 0%;
}

body {
  /* font-family: "Quicksand", sans-serif; */
  visibility: initial;
}

/* Utility stylings */
img {
  height: 100%;
  width: 100%;
  display: block;
}

.product-image {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-width: 250px;
  max-height: 360px;
  width: auto !important;
  height: auto !important;
  display: block !important;
  margin: auto !important;
}

.itm {
  margin: auto;
}

.ant-image {
  height: 100% !important;
  display: block !important;
}

.container {
  width: 88vw;
  margin: 0 auto;
}

.lg-title,
.md-title,
.sm-title {
  font-family: 'Roboto', sans-serif;
  padding: 0.6rem 0;
  text-transform: capitalize;
}

.lg-title {
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
  padding: 1.3rem 0;
  opacity: 0.9;
}

.md-title {
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
}

.sm-title {
  font-weight: 300;
  font-size: 1rem;
  text-transform: uppercase;
}

.text-light {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  opacity: 0.5;
  margin: 0.4rem 0;
}

/* product section */
.prothem {
  margin-top: 0%;
}

.products .text-light {
  text-align: center;
  width: 70%;
  margin: 0.9rem auto;
}

.product {
  max-width: 320px;
  width: 100%;
  height: auto;
  position: relative;
  padding: 1.2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  border: 1px solid rgb(223, 220, 220);
  border-radius: 20px;
  margin: 0 10px;
}

.product-content {
  background: var(--gray);
  padding: 1rem 0.5rem;
  cursor: pointer;
}

.tampro {
  max-width: 180px;
  margin: auto;
  margin-bottom: 20px;
}

.product-img {
  background: var(--white-light);
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 50%;
  transition: background 0.5s ease;
}

.product-btns {
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin-top: 1.4rem;
  opacity: 1;
  transition: opacity 0.6s ease;
}

.btn-cart,
.btn-buy {
  border-radius: 5px;
  background: transparent;
  border: 1px solid black;
  padding: 0.8rem 0;
  margin: 1px;
  width: 100%;
  font-family: inherit;
  text-transform: none;
  cursor: pointer;
  border: none;
  transition: all 0.6s ease;
  display: block;
}

.btn-cart {
  background: var(--color-principal);
  color: white;
  font-weight: bold;
}

.btn-buy {
  background-color: var(--color-principal);
  color: white;
  font-weight: bold;
}

.btn-cart:hover {
  background: var(--color-principal);
}

.btn-buy:hover {
  background: orangered;
}

.product-info {
  background: var(--alice-blue);
  padding: 1rem;
  display: table;
  width: 100%;
}

.product-info-content {
  display: table-cell;
}

.product-info-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rating span {
  color: var(--carribean-green);
}

.product-sku {
  color: gray;
  font-weight: normal;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  display: block;
  font-size: 1em;
}

.product-price-old {
  padding-top: 0.6rem;
  padding-right: 0.6rem;
  display: inline-block;
  text-decoration: line-through;

  font-weight: bold;
  font-size: 1em;
  color: gray;
}

.product-price {
  padding-top: 0.6rem;
  /* padding-bottom: 0.6rem; */
  display: block;

  font-weight: bold;
  font-size: 1.3em;
  color: black;
}

.product-price-old-text {
  color: indigo;
  font-weight: bold;
  padding-top: 0.6rem;
  padding-right: 0.6rem;
  display: inline-block;
  font-size: 0.8em;
}

.product:hover .product-btns {
  opacity: 1;
}

.off-info .sm-title {
  background: orangered;
  color: white;
  display: inline-block;
  padding: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  letter-spacing: 3px;
  cursor: pointer;
}

/* product collection */
.product-collection {
  padding: 3.2rem 0;
}

.product-collection-wrapper {
  padding: 3.2rem 0;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 50vh;
  padding: 2rem 1.5rem 3.2rem;
  margin: 5px;
}

.product-col-r-bottom>div:first-child {
  background: #eaa001;
}

.product-col-r-bottom>div:last-child {
  background: var(--color-principal);
}

.product-col-content {
  text-align: center;
  color: white;
}

.product-collection .text-light {
  opacity: 1;
  font-size: 0.8;
  font-weight: 400;
  line-height: 1.7;
}

.btn-dark {
  background: black;
  color: white;
  outline: 0;
  border-radius: 25px;
  padding: 0.7rem 1rem;
  border: 0;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.6s ease;
  font-size: 1rem;
  font-family: inherit;
}

.btn-dark:hover {
  background: var(--carribean-green);
}

.nodata {
  color: var(--color-principal);
  text-align: center;
  position: sticky;
  top: 50%;
}

.nodataContent {
  min-height: 200px;
}

.product-card-price,
.product-card-nombre {
  width: 100%;
}

.tamprecio {
  font-weight: 600;
}

.product-name {
  font-weight: 400;
}

.tamprecio,
.product-name {
  display: inline;
  color: rgb(2, 2, 2);
  text-decoration: none;
  text-align: center;
  font-size: 1rem;
  font-family:
    Open Sans,
    sans-serif;
}

.mycart3 {
  margin-top: 0%;
}

.mycart4 {
  margin-top: 0%;
}

/* Media Queries */
@media screen and (min-width: 100px) {
  .space {
    margin-top: -4%;
  }

  .bucartMob {
    margin-top: 6%;
    margin-left: -4%;
    display: flex;
  }

  .myact {
    color: #b11313;
    width: 10px;
    height: 10px;
  }

  .active2 {
    width: 10px;
    height: 20px;
    color: #069435;
  }

  .icon {
    width: 10px;
    height: 10px;
  }

  .add {
    margin-left: 1%;
    margin-top: 4.5%;
  }

  .addMob {
    margin-left: 1%;
    margin-top: 5.3%;
  }

  .tagmob {
    margin-top: 66%;
  }

  .productargeta {
    margin: 5%;
    text-align: center;
    margin-left: 2%;

    position: relative;
    height: 370px;
    width: 90%;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
    zoom: 80%;
  }

  .variant2 {
    margin-top: -2%;
    display: flex;
    padding-left: 5%;
  }

  .variant {
    margin-top: -38%;
    display: flex;
    padding-left: 8%;
  }

  .anterior {
    margin-right: 10%;
    width: 100%;
    font-size: 8px;
  }

  .preciomobt {
    margin-right: 10%;
    width: 100%;
    font-size: 10px;
  }

  .tampro {
    height: 100%;
    display: block;
    margin-top: -10%;
  }

  .tag {
    background-color: #ce4040;
    width: 30%;
    margin-top: -10%;
    height: 4%;
  }

  .fou {
    color: #fff;
    font-size: 10px;
  }

  .product4 {
    margin-top: -15%;
  }

  .product3 {
    margin-top: -1%;
  }

  .prothem {
    margin-top: 0%;
  }

  .mycart3 {
    margin-top: 9%;
  }

  .mycart4 {
    margin-top: 4%;
  }

  .product2 {
    text-align: center;
    position: relative;
    height: 90%;
    width: 90%;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }

  .productmobile {
    text-align: center;
    position: relative;
    height: 255px;
    width: 150px;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }

  .coleccionMobile {
    width: 100%;
    height: 100%;
    margin-top: 5%;
    padding-top: 65%;
  }

  .tamprecio,
  .product-name {
    text-align: left;
    font-size: 0.8rem;
  }

  .esp {
    margin-top: -45%;
  }

  .product-items {
    max-width: 400px;
    width: 110%;
    display: grid;
    margin-right: 0%;
    grid-template-columns: repeat(2, 1fr);
  }

  .product-content {
    background: var(--gray);
    /* padding: 0%; */
    cursor: pointer;
    /* margin-top: -15%; */
  }

  .product-image {
    position: relative;
    top: 60%;
    transform: translateY(-50%);
    max-width: 150px;
    max-height: 150px;
    width: 100%;
    height: 100%;
    display: block !important;
    margin: auto !important;
  }

  .product-img {
    background: var(--white-light);
    width: 100%;
    height: 150px;
    margin: 0 auto;
    border-radius: 100%;
    transition: background 0.5s ease;
  }

  .product-info {
    background: var(--alice-blue);
    padding: 1rem;
    display: table;
    width: 100%;
  }

  .product-info-content {
    display: table-cell;
  }

  .product-info-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .product-sku {
    color: gray;
    font-weight: normal;
    padding-top: 20%;
    padding-bottom: 1%;
    display: block;
    font-size: 1em;
  }

  .pdnames {
    color: rgb(2, 2, 2);
    display: block;
    text-decoration: none;
    text-align: left;
    padding-left: 5%;
    width: 90%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 8px;
    font-family:
      Open Sans,
      sans-serif;
    margin-top: -15%;
  }

  .product-namemob {
    color: rgb(2, 2, 2);
    text-decoration: none;
    text-align: left;
    padding-left: 1%;
    width: 130px;
    height: 10%;
    margin: 10%;
    letter-spacing: 1px;
    font-size: 15px;
    font-family:
      Open Sans,
      sans-serif;
    margin-top: 0%;
  }

  .product-price {
    padding-top: 0.6rem;
    display: flex;
    font-weight: bold;
    font-size: 1.3em;
    color: black;
  }

  .product-btns {
    text-decoration: none;
    display: flex;
    justify-content: center;
    margin-top: 1.4rem;
    opacity: 1;
    transition: opacity 0.6s ease;
  }
}

@media screen and (min-width: 750px) {
  .space {
    margin-top: 15%;
  }

  .tampro {
    width: 30%;
    height: 100%;
    display: block;
    text-align: left;
    margin-top: -10%;
  }

  .bucartMob {
    margin-top: 10%;
    margin-left: 6%;
    display: flex;
  }

  .myact {
    color: #b11313;
    width: 10px;
    height: 10px;
  }

  .active2 {
    width: 10px;
    height: 10px;
    color: #069435;
  }

  .icon {
    width: 10px;
    height: 10px;
  }

  .add {
    margin-left: 1%;
    margin-top: 2%;
  }

  .addMob {
    margin-left: 1%;
    margin-top: 2%;
  }

  .tagh {
    background-color: #ce4040;
    width: 15%;
    margin-top: 1%;
  }

  .tagmob {
    margin-top: 130%;
  }

  .productargeta {
    text-align: center;
    margin-left: 2%;

    position: relative;
    height: 470px;
    width: 90%;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
    zoom: 80%;
  }

  .pdnames {
    color: rgb(2, 2, 2);
    display: block;
    text-decoration: none;
    text-align: left;
    padding-left: 5%;
    width: 90%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 18px;
    font-family:
      Open Sans,
      sans-serif;
    margin-top: -5%;
  }

  .product3 {
    margin-top: -1%;
  }

  .tag {
    background-color: #ce4040;
    width: 15%;
    height: 4%;
  }

  .fou {
    color: #fff;
    font-size: 18px;
  }

  .anterior {
    margin-left: 40%;
    width: 120%;
    font-size: 120%;
    height: 20px;
  }

  .preciomobt {
    margin-right: 10%;
    width: 100%;
  }

  .variant {
    margin-top: -8%;
    display: flex;
    padding-left: 8%;
  }

  .variant2 {
    margin-top: -2%;
    display: flex;
    padding-left: 5%;
  }

  .esp {
    margin-top: -60%;
  }

  .coleccionMobile {
    width: 100%;
    height: 100%;
    margin-top: 5%;
    padding-top: 65%;
  }

  .product-items {
    max-width: 700px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .product-namemob {
    color: rgb(2, 2, 2);
    text-decoration: none;
    text-align: left;
    padding-left: 5%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 24px;
    font-family:
      Open Sans,
      sans-serif;
  }

  .product-namemob {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    padding-left: 1%;
    width: 130px;
    height: 10%;
    margin: 10%;

    letter-spacing: 1px;

    font-size: 15px;
    font-family:
      Open Sans,
      sans-serif;
    margin-top: 0%;
  }

  .tamprecio,
  .product-name {
    text-align: left;
    font-size: 1rem;
  }

  .itm {
    margin: auto;
    justify-content: center;
  }

  .prothem {
    margin-top: 10%;
  }

  .product2 {
    text-align: center;
    position: relative;
    height: 90%;
    width: 90%;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }

  .productmobile {
    text-align: center;
    position: relative;
    height: 90%;
    width: 90%;
    border-width: 1%;
    border-style: solid;
    border-radius: 3%;
    border-color: rgb(223, 220, 220);
    zoom: 80%;
  }

  .mycart3 {
    margin-top: 7%;
  }

  .mycart4 {
    margin-top: 0%;
  }

  .product-col-r-bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 992px) {
  .space {
    margin-top: -4%;
  }

  .bucartMob {
    margin-top: 6%;
    margin-left: 5%;
    display: flex;
  }

  .myact {
    color: #b11313;
    width: 10px;
    height: 10px;
  }

  .active2 {
    width: 10px;
    height: 10px;
    color: #069435;
  }

  .icon {
    width: 10px;
    height: 10px;
  }

  .add {
    margin-left: 1%;
    margin-top: 4%;
  }

  .addMob {
    margin-left: 1%;
    margin-top: 4%;
  }

  .tagmob {
    margin-top: 75%;
  }

  .productargeta {
    text-align: center;
    position: relative;
    height: 470px;
    width: 90%;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    margin-left: 2%;

    border-color: rgb(223, 220, 220);
    zoom: 80%;
  }

  .tag {
    background-color: #ce4040;
    width: 15%;
    height: 4%;
  }

  .fou {
    color: #fff;
    font-size: 18px;
  }

  .anterior {
    margin-left: 20%;
    width: 120%;
    font-size: 18px;
  }

  .preciomobt {
    margin-right: 10%;
    width: 100%;
  }

  .variant {
    margin-top: -18%;
    display: flex;
    padding-left: 8%;
  }

  .variant2 {
    margin-top: -2%;
    display: flex;
    padding-left: 5%;
  }

  .esp {
    margin-top: -60%;
  }

  .product-namemob {
    color: rgb(2, 2, 2);
    text-decoration: none;
    text-align: left;
    padding-left: 5%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 24px;
    font-family:
      Open Sans,
      sans-serif;
  }

  .coleccionMobile {
    width: 100%;
    height: 100%;
    margin-top: 5%;
    padding-top: 65%;
  }

  .product-items {
    display: grid;
    max-width: 800px;
    grid-template-columns: repeat(3, 1fr);
  }

  .product-col-r-bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .tamprecio,
  .product-name {
    text-align: left;
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 912px) {
  .space {
    margin-top: -4%;
  }

  .bucartMob {
    margin-top: 16%;
    margin-left: 5%;
    display: flex;
  }

  .myact {
    color: #b11313;
    width: 10px;
    height: 10px;
  }

  .active2 {
    width: 10px;
    height: 10px;
    color: #069435;
  }

  .icon {
    width: 10px;
    height: 10px;
  }

  .add {
    margin-left: 1%;
    margin-top: 2%;
  }

  .addMob {
    margin-left: 1%;
    margin-top: 2%;
  }

  .tampro {
    width: 30%;
    height: 100%;
    display: block;
    text-align: left;
    margin-top: 8%;
  }
}

@media screen and (min-width: 1000px) {
  .space {
    margin-top: 5%;
  }

  .bucartMob {
    margin-top: 8%;
    margin-left: 25%;
    display: flex;
  }

  .myact {
    color: #b11313;
    width: 20px;
    height: 20px;
  }

  .active2 {
    width: 20px;
    height: 20px;
    color: #069435;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .add {
    margin-left: 1%;
    margin-top: 3%;
  }

  .addMob {
    margin-left: 1%;
    margin-top: 3%;
  }

  .tampro {
    width: 50%;
    height: 100%;
    display: block;
    text-align: left;
    margin-top: 8%;
  }
}

@media screen and (min-width: 1200px) {

  .bucartMob {
    margin-top: 8%;
    margin-left: 30%;
    display: flex;
  }

  .myact {
    color: #b11313;
    width: 20px;
    height: 20px;
  }

  .active2 {
    width: 20px;
    height: 20px;
    color: #069435;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .add {
    margin-left: 1%;
    margin-top: 3%;
  }

  .addMob {
    margin-left: 1%;
    margin-top: 3%;
  }

  .tagh {
    background-color: #ce4040;
    width: 15%;
    margin-top: 1%;
  }

  .space {
    margin-top: 5%;
  }

  .product3 {
    margin-top: 7%;
  }

  .tagmob {
    margin-top: 88%;
  }

  .productargeta {
    text-align: center;
    position: relative;
    height: 470px;
    width: 90%;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
    zoom: 80%;
    margin-left: 2%;
  }

  .tag {
    background-color: #ce4040;
    width: 15%;
    height: 4%;
  }

  .fou {
    color: #fff;
    font-size: 18px;
  }

  .anterior {
    margin-left: 8%;
    width: 120%;
    font-size: 18px;
  }

  .preciomobt {
    margin-right: 30%;
    width: 120%;
    margin-top: -6%;
    font-size: 18px;
  }

  .variant {
    margin-top: 0%;
    display: flex;
    padding-left: 8%;
  }

  .variant2 {
    margin-top: -2%;
    display: flex;
    padding-left: 5%;
  }

  .product-namemob {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    padding-left: 5%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 24px;
    font-family:
      Open Sans,
      sans-serif;
  }

  .esp {
    margin-top: -60%;
  }

  .coleccionMobile {
    width: 100%;
    height: 100%;
    margin-top: 5%;
    padding-top: 65%;
  }

  .product-items {
    grid-template-columns: repeat(3, 1fr);
    max-width: 1200px;
    padding-bottom: 100px;
  }

  .product2 {
    text-align: center;
    position: relative;
    height: 90%;
    width: 90%;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }

  .productmobile {
    text-align: center;
    position: relative;
    height: 90%;
    width: 90%;
    border-width: 1%;
    border-style: solid;
    border-radius: 3%;
    border-color: rgb(223, 220, 220);
    zoom: 80%;
  }

  .products .text-light {
    width: 50%;
  }

  .tamprecio,
  .product-name {
    text-align: left;
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1336px) {
  .space {
    margin-top: 6%;
  }

  .myact {
    color: #b11313;
    width: 20px;
    height: 20px;
  }

  .active2 {
    width: 20px;
    height: 25px;
    color: #069435;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .bucartMob {
    margin-top: 6%;
    margin-left: 30%;
    display: flex;
  }

  .add {
    margin-left: 1%;
    margin-top: 2%;
  }

  .addMob {
    margin-left: 1%;
    margin-top: 2%;
  }

  .product-content {
    background: var(--gray);
    cursor: pointer;
  }

  .product-namemob {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    padding-left: 5%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 24px;
    font-family:
      Open Sans,
      sans-serif;
  }

  .coleccionMobile {
    width: 100%;
    height: 100%;
    margin-top: 5%;
    padding-top: 65%;
  }

  .esp {
    margin-top: -30%;
  }

  .product-items {
    grid-template-columns: repeat(4, 1fr);
    max-width: 1330px;
    margin-right: 5%;
  }

  .product-collection-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .flex {
    height: 60vh;
  }

  .product-col-left {
    height: 121.5vh;
  }

  .pdnames {
    color: rgb(2, 2, 2);
    display: block;
    text-decoration: none;
    text-align: left;
    padding-left: 10%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 24px;
    font-family:
      Open Sans,
      sans-serif;
    margin-top: 0%;
  }

  .tamprecio,
  .product-name {
    text-align: left;
    font-size: 1.2rem;
  }

  .itm {
    margin: auto;
    justify-content: center;
  }

  .prothem {
    margin-top: 10%;
  }

  .product2 {
    text-align: center;
    position: relative;
    height: 90%;
    width: 90%;
    border-width: 1%;
    border-style: solid;
    border-radius: 3%;
    border-color: rgb(223, 220, 220);
    zoom: 80%;
  }

  .productmobile {
    text-align: center;
    position: relative;
    height: 90%;
    width: 95%;
    border-width: 1%;
    border-style: solid;
    border-radius: 3%;
    border-color: rgb(223, 220, 220);
    zoom: 80%;
  }

  .productargeta {
    text-align: center;
    position: relative;
    height: 470px;
    width: 90%;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
    zoom: 80%;

    margin-left: 20%;
  }

  .product4 {
    margin-top: 0%;
  }

  .product3 {
    margin-top: 4%;
    text-align: left;
    display: flex;
    width: 150%;
    height: 35px;
  }

  .mycart3 {
    margin-top: 8%;
  }

  .mycart4 {
    margin-top: 9%;
  }

  .side-bar .side-box {
    float: left;
    width: 100%;
    margin-bottom: 30px;
    border-radius: 5px;
  }

  .side-bar .box-heading {
    color: #000;
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    margin-block: 5px;
    text-transform: capitalize;
    padding: 10px 15px;
    position: relative;
    margin-block-start: 0;
    background-color: rgb(228, 220, 220);
    letter-spacing: 0.2px;
  }

  .side-bar .box-content {
    float: left;
    width: 100%;
    position: relative;
    margin: 5px 0 0;
    padding: 5px 20px 10px;
  }

  .tag {
    background-color: #ce4040;
    width: 15%;
    margin-top: -10%;
    height: 4%;
  }

  .tagh {
    background-color: #ce4040;
    width: 15%;
    margin-top: -1%;
  }

  .tagmob {
    margin-top: 100%;
  }

  .fou {
    color: #fff;
    font-size: 18px;
    margin-top: 10%;
  }

  .tampro {
    width: 60%;
    height: 100%;
    display: block;
    text-align: left;
  }

  .anterior {
    margin-left: -25%;
    width: 120%;
    font-size: 18px;
  }

  .variant {
    margin-top: -18%;
    display: flex;
    padding-left: 8%;
  }

  .variant2 {
    margin-top: -2%;
    display: flex;
    padding-left: 5%;
  }

  .preciomobt {
    margin-right: 30%;
    width: 120%;
    margin-top: 0%;
    font-size: 18px;
  }
}

/* Fin Card */
.titleColeccion1 {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  font-family:
    Open Sans,
    sans-serif;
  letter-spacing: 1px;
  color: rgb(61, 59, 59);
}
.titleColeccion2 {
  text-align: center;
  font-size: 20px;
}
.ul li {
  text-transform: none;
  list-style: none;
  display: inline-block;
  padding: 0 2px !important;
}
.puntuacion {
  font-size: 140%;
}
@media screen and (min-width: 100px) {
  .puntuacion {
    font-size: 100%;
  }
  .titleColeccion1 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family:
      Open Sans,
      sans-serif;
    letter-spacing: 1px;
    color: rgb(61, 59, 59);
  }
  .titleColeccion2 {
    text-align: center;
    font-size: 15px;
  }
  .ul li {
    text-transform: none;
    list-style: none;
    display: inline-block;
    padding: 0 2px !important;
  }
}
@media screen and (min-width: 1336px) {
  .puntuacion {
    font-size: 140%;
  }
  .titleColeccion1 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family:
      Open Sans,
      sans-serif;
    letter-spacing: 1px;
    color: rgb(61, 59, 59);
  }
  .ul li {
    text-transform: none;
    list-style: none;
    display: inline-block;
    padding: 0 2px !important;
  }
  .titleColeccion2 {
    text-align: center;
    font-size: 15px;
  }
}

/* 
COLORES 
background: #FFFAE5
main: #E1A13F
secondary: 
textos: #0D0D0D

TEXTOS
font-family: 'Crimson Text', serif;
font-family: 'Josefin Sans', sans-serif;


background-color: #DCD9D4;
 background-image: linear-gradient(to bottom, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%), radial-gradient(at 50% 0%, rgba(255,255,255,0.10) 0%, rgba(0,0,0,0.50) 50%);
 background-blend-mode: soft-light,screen;


 background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;
 background-blend-mode: multiply,multiply;
*/

/*#region VARIABLES*/
:root {
  --primary-color: #67655f;
  --background-color: #ffffff;
  --texts-color: #0d0d0d;
  --background-secondary: rgba(0, 0, 0, 0.05);
  --gradient: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(0, 0, 0, 0.15) 100%
    ),
    radial-gradient(
      at top center,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 120%
    );
}
/*#endregion*/

/*#region GENERALES*/

.text-justify {
  text-align: justify;
}

.btn-main {
  background-color: var(--color-principal) !important;
  color: #fff !important;
  border: 2px solid var(--color-principal) !important;
}

.btn-main:hover,
.btn-secondary:hover {
  background-color: var(--primary-color) !important;
  border: 2px solid var(--primary-color) !important;
}

.btn-secondary {
  border: 2px solid var(--primary-color) !important;
  background-color: transparent !important;
}

.linkAnimated {
  display: inline-block;
  position: relative;
}

.linkAnimated::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: var(--primary-color);
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transition: 0.25s linear;
}

.linkAnimated:hover::after {
  visibility: visible;
  transform: scaleX(1);
}
/*#endregion*/

/*#region LOGIN AND REGISTER*/
.login-bg {
  background-image: url(/static/media/model1.bdfbd297.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.navLogin {
  background-color: #fff;
}

.register-bg {
  background-image: url(/static/media/model2.14f27d54.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}
/*#endregion*/

/* #region NAVBAR */
.wrap {
  background-color: var(--primary-color);
  color: #fff;
}

.nav-link {
  display: inline-block;
  position: relative;
  color: var(--primary-color);
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: var(--primary-color);
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transition: 0.25s linear;
}

.nav-link:hover::after {
  visibility: visible;
  transform: scaleX(1);
}

.inputs {
  position: relative;
  align-items: center;
  display: flex;
}

.search {
  text-indent: 15px;
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #eee !important;
  background-color: transparent !important;
}

.search:focus {
  color: #b8b9bc !important;
  background-color: #fff;
  border-color: #eee !important;
  outline: 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid var(--primary-color) !important;
}

.search:focus {
  color: var(--texts-color) !important;
}

/* #endregion */
h1,
h2,
h3,
h4 {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400 !important;
}

h1 {
  font-size: 5em !important;
}
/*#region SECTION MAIN*/
.main-section {
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(0, 0, 0, 0.15) 100%
    ),
    radial-gradient(
      at top center,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 120%
    ),
    var(--background) !important;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
}
/* #endregion */

/*#region Novedades*/

.ratings i {
  color: #cecece;
  font-size: 16px;
}

.rating-color {
  color: #fbc634 !important;
}
/*#endregion*/

/*#region ourCategories*/
.ourCategories {
  background-color: var(--background-secondary);
  padding: 40px;
  height: 100vh;
  display: flex;
  align-items: center;
}
.ali {
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: -50%;
}
.card-img-overlay {
  background: none;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}
/*#endregion*/

/*#region bestSellers*/
.bestSellers {
  padding: 40px;
}
.bestSellers span {
  background-color: var(--primary-color);
  color: #fff;
  padding: 3px 10px;
}
.imgs {
  display: block;
  margin: auto;
  width: 30%;
}
.carousel-item img {
  margin: auto;
}
/*#endregion*/

/*#region collections*/
.collections {
  padding: 40px;
  background: var(--background-secondary);
}

.collections p {
  text-align: justify;
}

/*#endregion*/

/*#region separator*/
.separator div {
  background-color: var(--primary-color);
  height: 20px;
}
/*#endregion*/

/*#region footer*/

/*#endregion*/

/* #region MEDIA QUERIES */

/* #endregion */

@media screen and (max-width: 500px) {
  .bestSellers {
    padding: 40px;
    margin-top: 60%;
  }
  .main-section {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(0, 0, 0, 0.15) 100%
      ),
      radial-gradient(
        at top center,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 120%
      ),
      var(--background) !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* min-height: 100vh; */
    display: flex;
    align-items: center;
  }
  img,
  svg {
    vertical-align: middle;
    /* margin-top: 5%; */
  }
  .col-4 {
    flex: 0 0 auto;
    width: 100%;
  }
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: block;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  .ourCategories {
    background-color: var(--background-secondary);
    padding: 40px;
    height: 100vh;
    display: flex;
    align-items: center;
    margin-top: 60%;
  }
}

@media screen and (min-width: 1336px) {
  /* 
COLORES 
background: #FFFAE5
main: #E1A13F
secondary: 
textos: #0D0D0D

TEXTOS
font-family: 'Crimson Text', serif;
font-family: 'Josefin Sans', sans-serif;


background-color: #DCD9D4;
 background-image: linear-gradient(to bottom, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%), radial-gradient(at 50% 0%, rgba(255,255,255,0.10) 0%, rgba(0,0,0,0.50) 50%);
 background-blend-mode: soft-light,screen;


 background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;
 background-blend-mode: multiply,multiply;
*/

  /*#region VARIABLES*/
  :root {
    --primary-color: #67655f;
    --background-color: #ffffff;
    --texts-color: #0d0d0d;
    --background-secondary: rgba(0, 0, 0, 0.05);
    --gradient: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(0, 0, 0, 0.15) 100%
      ),
      radial-gradient(
        at top center,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 120%
      );
  }
  /*#endregion*/

  /*#region GENERALES*/

  .text-justify {
    text-align: justify;
  }

  .btn-main {
    background-color: var(--color-principal) !important;
    color: #fff !important;
    border: 2px solid var(--color-principal) !important;
  }

  .btn-main:hover,
  .btn-secondary:hover {
    background-color: var(--primary-color) !important;
    border: 2px solid var(--primary-color) !important;
  }

  .btn-secondary {
    border: 2px solid var(--primary-color) !important;
    background-color: transparent !important;
  }

  .linkAnimated {
    display: inline-block;
    position: relative;
  }

  .linkAnimated::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background: var(--primary-color);
    visibility: hidden;
    border-radius: 5px;
    transform: scaleX(0);
    transition: 0.25s linear;
  }

  .linkAnimated:hover::after {
    visibility: visible;
    transform: scaleX(1);
  }
  /*#endregion*/

  /*#region LOGIN AND REGISTER*/
  .login-bg {
    background-image: url(/static/media/model1.bdfbd297.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
  }

  .navLogin {
    background-color: #fff;
  }

  .register-bg {
    background-image: url(/static/media/model2.14f27d54.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
  }
  /*#endregion*/

  /* #region NAVBAR */
  .wrap {
    background-color: var(--primary-color);
    color: #fff;
  }

  .nav-link {
    display: inline-block;
    position: relative;
    color: var(--primary-color);
  }

  .nav-link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background: var(--primary-color);
    visibility: hidden;
    border-radius: 5px;
    transform: scaleX(0);
    transition: 0.25s linear;
  }

  .nav-link:hover::after {
    visibility: visible;
    transform: scaleX(1);
  }

  .inputs {
    position: relative;
    align-items: center;
    display: flex;
  }

  .search {
    text-indent: 15px;
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid #eee !important;
    background-color: transparent !important;
  }

  .search:focus {
    color: #b8b9bc !important;
    background-color: #fff;
    border-color: #eee !important;
    outline: 0 !important;
    box-shadow: none !important;
    border-bottom: 1px solid var(--primary-color) !important;
  }

  .search:focus {
    color: var(--texts-color) !important;
  }

  /* #endregion */
  h1,
  h2,
  h3,
  h4 {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400 !important;
  }

  h1 {
    font-size: 5em !important;
  }
  /*#region SECTION MAIN*/
  .main-section {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(0, 0, 0, 0.15) 100%
      ),
      radial-gradient(
        at top center,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 120%
      ),
      var(--background) !important;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
  /* #endregion */

  /*#region Novedades*/

  .ratings i {
    color: #cecece;
    font-size: 16px;
  }

  .rating-color {
    color: #fbc634 !important;
  }
  /*#endregion*/

  /*#region ourCategories*/
  .ourCategories {
    background-color: var(--background-secondary);
    padding: 40px;
    height: 100vh;
    display: flex;
    align-items: center;
  }
  .ali {
    color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: -50%;
  }
  .card-img-overlay {
    background: none;
    color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  /*#endregion*/

  /*#region bestSellers*/
  .bestSellers {
    padding: 40px;
  }
  .bestSellers span {
    background-color: var(--primary-color);
    color: #fff;
    padding: 3px 10px;
  }
  .imgs {
    display: block;
    margin: auto;
    width: 30%;
  }
  .carousel-item img {
    margin: auto;
  }
  /*#endregion*/

  /*#region collections*/
  .collections {
    padding: 40px;
    background: var(--background-secondary);
  }

  .collections p {
    text-align: justify;
  }

  /*#endregion*/

  /*#region separator*/
  .separator div {
    background-color: var(--primary-color);
    height: 20px;
  }
  /*#endregion*/

  /*#region footer*/

  /*#endregion*/

  /* #region MEDIA QUERIES */

  /* #endregion */
}

.navbar {
  margin-top: 20px;
  background-color: transparent;
  margin-left: 5%;

  align-items: center;
  width: 100%;

  top: 0;
}

.tittle {
  margin-left: -800%;
}
.coleccion {
  margin-left: 5%;
}
.hola {
  color: red;
}

.menu-icon {
  background-color: transparent;
  list-style: square;
  color: transparent;
}

.menu-icon-filtro {
  background-color: transparent;
  list-style: none;
  margin-left: 20px;
}

.menu-bars {
  margin-top: 55px;
  font-size: 18px;
  list-style: none;
  display: flex;
  text-transform: none;
  background-color: white;
  /* background: transparent url("../svg/cd-icon-filter.svg") no-repeat center center; */
}

.menu-barsclose {
  width: 100%;
  text-align: end;
  color: var(--color-principal) !important;
  margin-top: -20px;
}

.menu-barsaplicar .app {
  padding: 5px;
  color: white;
  border-radius: 5px;
  background-color: #ffffff;
}

.menu-barsaplicar {
  width: 100%;
  text-align: start;
}

/* .menu-bars ::after{
  content: ('Filtro');
   } */

.ant-collapse-header {
  color: white;
  cursor: pointer;
  padding: 5px;
  font-size: 18px;
  font-weight: bold;
}

.anticon {
  margin-right: 10px;
}

.ant-collapse-item ant-collapse-item-active {
  border-color: transparent;
}

.ant-collapse-content {
  width: 100%;
}

.ant-collapse-content-inactive {
  display: none;
}

.ant-collapse-content-active {
  display: block;
}

.filtertext {
  color: #8f83b8;
  margin-left: 5px;
}

.nt-checkbox-input {
  margin-right: 15px;
}

.item-check {
  margin-right: 10px;
}

.check-item-cont {
  margin-left: 20px;
  display: flex;
  padding: 5px;
  cursor: pointer;
  color: white;
}

.nav-menu {
  background-color: white;
  width: 300px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 999;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  overflow: auto;
  border: gray;
  border-width: 1px;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #060b26;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  width: 100%;
}

ul li {
  text-transform: none;
  list-style: none;
  display: inline-block;
  padding: 0 15px;
}

.nav-menu.active {
  background-color: #ffffff;
  overflow-y: scroll;
  z-index: 999999;
  overflow: auto;
  border: gray;
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  width: 25%;
}

.navbar-toggle {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* span {
    margin-left: 16px;
  } */

/* @media screen and (max-width: 600px){
    .nav-menu {
      
      width: 80%;
      
     
    }
  
  } */
@media screen and (min-width: 1336px) {
  .tittle {
    margin-left: -800%;
  }
  ul li {
    text-transform: none;
    list-style: none;
    display: inline-block;
    padding: 0px;
  }
}
@media screen and (min-width: 100px) {
  .tittle {
    margin-left: 5%;

    background-color: transparent;
    white-space: pre;
    align-items: center;
    position: sticky;
    top: 0;
  }
  ul li {
    text-transform: none;
    list-style: none;
    display: inline-block;
    padding: 0px;
  }
}

.card {
  opacity: 0.5 !important;
}

.buscando {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 2.4rem !important;
  text-align: center;
  width: 100%;
}
.tittle {
  background-color: transparent;
  font-size: 28px;

  width: 100%;
  margin-right: 85%;
  display: flex;
  position: sticky;
}
.desk-banner {
  margin-top: 15px;
}
@media screen and (min-width: 100px) {
  .tittle {
    background-color: transparent;
    font-size: 16px;
    width: 100%;
    margin-right: 70%;
    display: flex;
    position: sticky;
  }
}
@media screen and (min-width: 1336px) {
  .tittle {
    background-color: transparent;
    font-size: 28px;
    width: 100%;
    margin-right: 73%;
    display: flex;
    position: sticky;
  }
}

.app {
  height: 90%;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.im {
  display: block;
  margin: auto;
}

.section {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 50px;
}

.ventajas {
  color: #051b4e;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  max-width: 650px;
  width: 100%;
  margin: 20px;
}

.list-int {
  justify-content: center;
  height: 249px;
  color: white;
  max-width: 300px;
  width: 100%;
  padding: 10px;
  background-color: #003462;
  line-height: 30px;
  border-radius: 5px;
}

.centered {
  font-size: 18px;
  width: 100%;
  text-align: center;
  color: white;
}

.pointer li {
  display: list-item !important;
  font-weight: bold;
  font-size: 15px;
}

.pointer {
  list-style-type: circle !important;
  list-style: square;
}

.ventajas-title {
  margin-bottom: 10px;
  font-size: 30px;
}

.letra {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 50px;
}
.t {
  display: block;
}
.formulario {
  background-color: rgb(253, 253, 253);
}
.caja {
  /* margin: 4em; */
  margin: 1em;
  padding: 1em;
  box-shadow: 1px 2px 9px #000;
}
.infor {
  padding-right: 30%;
  height: 200%;
  margin-left: 25%;
  margin-top: 0%;
}
.captcha {
  margin-left: 5%;
}
.e {
  padding-left: 0%;
  padding-top: 3%;
}
.clientes {
  font-size: 50%;
}
.banner {
  margin-left: 25%;
}
@media screen and (min-width: 500px) {
  .formulario {
    background-color: rgb(253, 253, 253);
    width: 50%;
    height: 50%;
    margin-top: 0%;
    margin-left: 50%;
  }
  .captcha {
    margin-left: 13%;
  }
  .infor {
    padding-right: 0%;
    width: 30%;
    margin-left: 12%;
    margin-top: 20%;
  }
  .caja {
    /* margin: 4em; */
    margin: 4em;
    padding: 1em;
    box-shadow: 1px 2px 9px #000;
    margin-top: -40%;
  }
  .banner {
    margin-left: 0%;
    width: 100%;
  }
  .clientes {
    font-size: 70%;
  }
  .letra {
    font-family: 'Titillium Web', sans-serif;
    font-weight: 300;
    font-size: 50%;
  }
  .t {
    width: 50%;
    text-align: justify;
    margin-left: 1.5%;
  }

  .e {
    padding-left: 2%;
    padding-top: 0%;
  }
}

#me-tab .MuiBox-root {
  padding-top: 0;
}

.MuiSvgIcon-root.pedido-detalle-icon {
  fill: white !important;
}

.pedido-detalle-stepper
  .MuiTypography-root.MuiStepLabel-label.MuiStepLabel-alternativeLabel.MuiTypography-body2.MuiTypography-displayBlock {
  margin-top: 5px !important;
}

.MuiTableCell-root {
  padding: 8px !important;
}

.MuiTableCell-root.table-no-padding {
  padding: 0px !important;
  border: none;
}

.MuiTableCell-root.table-bottom {
  padding: 0px !important;
}

i.icon {
  margin: 0 !important;
  height: unset !important;
}

i.icon.warning-icon {
  margin-left: 10px !important;
}

/* .ant-image-preview-mask {
  z-index: 9000 !important;
}

.ant-image-preview-wrap {
  z-index: 9080 !important;
}

.ant-image-preview-img-wrapper::before {
  height: 0 !important;
  display: flex !important;
}

.ant-image-preview-operations {
  background: rgba(0, 0, 0, 0.5) !important;
} */

.MuiTable-root {
  min-width: 200px !important;
}
.icons {
  fill: #ffffff !important;
}


/* 
COLORES 
background: #FFFAE5
main: #E1A13F
secondary: 
textos: #0D0D0D

TEXTOS
font-family: 'Crimson Text', serif;
font-family: 'Josefin Sans', sans-serif;


background-color: #DCD9D4;
 background-image: linear-gradient(to bottom, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%), radial-gradient(at 50% 0%, rgba(255,255,255,0.10) 0%, rgba(0,0,0,0.50) 50%);
 background-blend-mode: soft-light,screen;


 background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;
 background-blend-mode: multiply,multiply;
*/

/*#region VARIABLES*/
:root {
  --primary-color: #67655f;
  --background-color: #ffffff;
  --texts-color: #0d0d0d;
  --background-secondary: rgba(0, 0, 0, 0.05);
  --gradient: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(0, 0, 0, 0.15) 100%
    ),
    radial-gradient(
      at top center,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 120%
    );
}
/*#endregion*/

/*#region GENERALES*/

.text-justify {
  text-align: justify;
}

.btn-main {
  background-color: var(--color-principal) !important;
  color: #fff !important;
  border: 2px solid var(--color-principal) !important;
}

.btn-main:hover,
.btn-secondary:hover {
  background-color: var(--primary-color) !important;
  border: 2px solid var(--primary-color) !important;
}

.btn-secondary {
  border: 2px solid var(--primary-color) !important;
  background-color: transparent !important;
}

.linkAnimated {
  display: inline-block;
  position: relative;
}

.linkAnimated::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: var(--primary-color);
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transition: 0.25s linear;
}

.linkAnimated:hover::after {
  visibility: visible;
  transform: scaleX(1);
}
/*#endregion*/

/*#region LOGIN AND REGISTER*/
.login-bg {
  background-image: url('../img/model1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.navLogin {
  background-color: #fff;
}

.register-bg {
  background-image: url('../img/model2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}
/*#endregion*/

/* #region NAVBAR */
.wrap {
  background-color: var(--primary-color);
  color: #fff;
}

.nav-link {
  display: inline-block;
  position: relative;
  color: var(--primary-color);
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: var(--primary-color);
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transition: 0.25s linear;
}

.nav-link:hover::after {
  visibility: visible;
  transform: scaleX(1);
}

.inputs {
  position: relative;
  align-items: center;
  display: flex;
}

.search {
  text-indent: 15px;
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #eee !important;
  background-color: transparent !important;
}

.search:focus {
  color: #b8b9bc !important;
  background-color: #fff;
  border-color: #eee !important;
  outline: 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid var(--primary-color) !important;
}

.search:focus {
  color: var(--texts-color) !important;
}

/* #endregion */
h1,
h2,
h3,
h4 {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400 !important;
}

h1 {
  font-size: 5em !important;
}
/*#region SECTION MAIN*/
.main-section {
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(0, 0, 0, 0.15) 100%
    ),
    radial-gradient(
      at top center,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 120%
    ),
    var(--background) !important;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
}
/* #endregion */

/*#region Novedades*/

.ratings i {
  color: #cecece;
  font-size: 16px;
}

.rating-color {
  color: #fbc634 !important;
}
/*#endregion*/

/*#region ourCategories*/
.ourCategories {
  background-color: var(--background-secondary);
  padding: 40px;
  height: 100vh;
  display: flex;
  align-items: center;
}
.ali {
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: -50%;
}
.card-img-overlay {
  background: none;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}
/*#endregion*/

/*#region bestSellers*/
.bestSellers {
  padding: 40px;
}
.bestSellers span {
  background-color: var(--primary-color);
  color: #fff;
  padding: 3px 10px;
}
.imgs {
  display: block;
  margin: auto;
  width: 30%;
}
.carousel-item img {
  margin: auto;
}
/*#endregion*/

/*#region collections*/
.collections {
  padding: 40px;
  background: var(--background-secondary);
}

.collections p {
  text-align: justify;
}

/*#endregion*/

/*#region separator*/
.separator div {
  background-color: var(--primary-color);
  height: 20px;
}
/*#endregion*/

/*#region footer*/

/*#endregion*/

/* #region MEDIA QUERIES */

/* #endregion */

@media screen and (max-width: 500px) {
  .bestSellers {
    padding: 40px;
    margin-top: 60%;
  }
  .main-section {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(0, 0, 0, 0.15) 100%
      ),
      radial-gradient(
        at top center,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 120%
      ),
      var(--background) !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* min-height: 100vh; */
    display: flex;
    align-items: center;
  }
  img,
  svg {
    vertical-align: middle;
    /* margin-top: 5%; */
  }
  .col-4 {
    flex: 0 0 auto;
    width: 100%;
  }
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: block;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  .ourCategories {
    background-color: var(--background-secondary);
    padding: 40px;
    height: 100vh;
    display: flex;
    align-items: center;
    margin-top: 60%;
  }
}

@media screen and (min-width: 1336px) {
  /* 
COLORES 
background: #FFFAE5
main: #E1A13F
secondary: 
textos: #0D0D0D

TEXTOS
font-family: 'Crimson Text', serif;
font-family: 'Josefin Sans', sans-serif;


background-color: #DCD9D4;
 background-image: linear-gradient(to bottom, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%), radial-gradient(at 50% 0%, rgba(255,255,255,0.10) 0%, rgba(0,0,0,0.50) 50%);
 background-blend-mode: soft-light,screen;


 background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;
 background-blend-mode: multiply,multiply;
*/

  /*#region VARIABLES*/
  :root {
    --primary-color: #67655f;
    --background-color: #ffffff;
    --texts-color: #0d0d0d;
    --background-secondary: rgba(0, 0, 0, 0.05);
    --gradient: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(0, 0, 0, 0.15) 100%
      ),
      radial-gradient(
        at top center,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 120%
      );
  }
  /*#endregion*/

  /*#region GENERALES*/

  .text-justify {
    text-align: justify;
  }

  .btn-main {
    background-color: var(--color-principal) !important;
    color: #fff !important;
    border: 2px solid var(--color-principal) !important;
  }

  .btn-main:hover,
  .btn-secondary:hover {
    background-color: var(--primary-color) !important;
    border: 2px solid var(--primary-color) !important;
  }

  .btn-secondary {
    border: 2px solid var(--primary-color) !important;
    background-color: transparent !important;
  }

  .linkAnimated {
    display: inline-block;
    position: relative;
  }

  .linkAnimated::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background: var(--primary-color);
    visibility: hidden;
    border-radius: 5px;
    transform: scaleX(0);
    transition: 0.25s linear;
  }

  .linkAnimated:hover::after {
    visibility: visible;
    transform: scaleX(1);
  }
  /*#endregion*/

  /*#region LOGIN AND REGISTER*/
  .login-bg {
    background-image: url('../img/model1.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
  }

  .navLogin {
    background-color: #fff;
  }

  .register-bg {
    background-image: url('../img/model2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
  }
  /*#endregion*/

  /* #region NAVBAR */
  .wrap {
    background-color: var(--primary-color);
    color: #fff;
  }

  .nav-link {
    display: inline-block;
    position: relative;
    color: var(--primary-color);
  }

  .nav-link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background: var(--primary-color);
    visibility: hidden;
    border-radius: 5px;
    transform: scaleX(0);
    transition: 0.25s linear;
  }

  .nav-link:hover::after {
    visibility: visible;
    transform: scaleX(1);
  }

  .inputs {
    position: relative;
    align-items: center;
    display: flex;
  }

  .search {
    text-indent: 15px;
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid #eee !important;
    background-color: transparent !important;
  }

  .search:focus {
    color: #b8b9bc !important;
    background-color: #fff;
    border-color: #eee !important;
    outline: 0 !important;
    box-shadow: none !important;
    border-bottom: 1px solid var(--primary-color) !important;
  }

  .search:focus {
    color: var(--texts-color) !important;
  }

  /* #endregion */
  h1,
  h2,
  h3,
  h4 {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400 !important;
  }

  h1 {
    font-size: 5em !important;
  }
  /*#region SECTION MAIN*/
  .main-section {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(0, 0, 0, 0.15) 100%
      ),
      radial-gradient(
        at top center,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 120%
      ),
      var(--background) !important;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
  /* #endregion */

  /*#region Novedades*/

  .ratings i {
    color: #cecece;
    font-size: 16px;
  }

  .rating-color {
    color: #fbc634 !important;
  }
  /*#endregion*/

  /*#region ourCategories*/
  .ourCategories {
    background-color: var(--background-secondary);
    padding: 40px;
    height: 100vh;
    display: flex;
    align-items: center;
  }
  .ali {
    color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: -50%;
  }
  .card-img-overlay {
    background: none;
    color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  /*#endregion*/

  /*#region bestSellers*/
  .bestSellers {
    padding: 40px;
  }
  .bestSellers span {
    background-color: var(--primary-color);
    color: #fff;
    padding: 3px 10px;
  }
  .imgs {
    display: block;
    margin: auto;
    width: 30%;
  }
  .carousel-item img {
    margin: auto;
  }
  /*#endregion*/

  /*#region collections*/
  .collections {
    padding: 40px;
    background: var(--background-secondary);
  }

  .collections p {
    text-align: justify;
  }

  /*#endregion*/

  /*#region separator*/
  .separator div {
    background-color: var(--primary-color);
    height: 20px;
  }
  /*#endregion*/

  /*#region footer*/

  /*#endregion*/

  /* #region MEDIA QUERIES */

  /* #endregion */
}

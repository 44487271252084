/* Card */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700;900&display=swap');

/*@import url("../../../Views/Colores.js");*/
.fa,
.fas {
  color: orange;
  font-weight: 900;
}

.spa {
  margin-top: 5%;
}

.space {
  margin-top: 5%;
}

.product4 {
  margin-top: 0%;
}

.myact {
  color: #b11313;
  width: 20px;
  height: 20px;
}

.active2 {
  width: 20px;
  height: 20px;
  color: #069435;
}

.add {
  margin-left: 1%;
  margin-top: 2%;
}

.addMob {
  margin-left: 1%;
  margin-top: 2%;
}

.bucart {
  display: flex;
}

.bucartMob {
  margin-top: 0%;
  margin-left: 10%;
  display: flex;
}

.icon {
  width: 20px;
  height: 20px;
}

.add {
  margin-left: 1%;
  margin-top: 2%;
}

.addMob {
  margin-left: 1%;
  margin-top: 2%;
}

.nolink {
  text-decoration: none;
  text-transform: none;
}

.variant {
  margin-top: -8%;
  display: flex;
  padding-left: 8%;
}

.variant2 {
  margin-top: -8%;
  display: flex;
  padding-left: 8%;
}

.anterior {
  margin-right: 10%;
  width: 100%;
}

.preciomobt {
  margin-right: 10%;
  width: 100%;
}

.product3 {
  margin-top: 13%;
}

.fou {
  color: #fff;
  font-size: 10px;
}

.pdnames {
  color: rgb(2, 2, 2);
  /* display: block; */
  text-decoration: none;
  text-align: left;
  padding-left: 5%;
  width: 100%;
  letter-spacing: 1px;
  height: 30%;
  font-size: 24px;
  font-family:
    Open Sans,
    sans-serif;
  margin-top: 0%;
}

.esp {
  margin-top: -60%;
}

.side-bar .side-box {
  float: left;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 5px;
}

.side-bar .box-heading {
  color: #000;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  margin-block: 5px;
  text-transform: capitalize;
  padding: 10px 15px;
  position: relative;
  margin-block-start: 0;
  background-color: rgb(228, 220, 220);
  letter-spacing: 0.2px;
}

.side-bar .box-content {
  float: left;
  width: 100%;
  position: relative;
  margin: 5px 0 0;
  padding: 5px 20px 10px;
}

.tag {
  background-color: #ce4040;
  width: 15%;
  height: 4%;
}

.tag2 {
  background-color: #ce4040;
  width: 15%;
  margin-top: 2%;
}

.tagmob {
  margin-top: 75%;
}

.coleccion {
  width: 100%;
  height: 70%;
  margin-top: 7%;
  margin: 0 auto;
}

.coleccionMobile {
  width: 100%;
  height: 100%;
  margin-top: 5%;
  padding-top: 50%;
}

:root {
  --color-principal: white;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.product-namemob {
  color: rgb(2, 2, 2);
  /* display: block; */
  text-decoration: none;
  text-align: left;
  padding-left: 5%;
  width: 100%;
  letter-spacing: 1px;
  height: 30%;
  font-size: 24px;
  font-family:
    Open Sans,
    sans-serif;
  margin-top: 0%;
}

body {
  /* font-family: "Quicksand", sans-serif; */
  visibility: initial;
}

/* Utility stylings */
img {
  height: 100%;
  width: 100%;
  display: block;
}

.product-image {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-width: 250px;
  max-height: 360px;
  width: auto !important;
  height: auto !important;
  display: block !important;
  margin: auto !important;
}

.itm {
  margin: auto;
}

.ant-image {
  height: 100% !important;
  display: block !important;
}

.container {
  width: 88vw;
  margin: 0 auto;
}

.lg-title,
.md-title,
.sm-title {
  font-family: 'Roboto', sans-serif;
  padding: 0.6rem 0;
  text-transform: capitalize;
}

.lg-title {
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
  padding: 1.3rem 0;
  opacity: 0.9;
}

.md-title {
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
}

.sm-title {
  font-weight: 300;
  font-size: 1rem;
  text-transform: uppercase;
}

.text-light {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  opacity: 0.5;
  margin: 0.4rem 0;
}

/* product section */
.prothem {
  margin-top: 0%;
}

.products .text-light {
  text-align: center;
  width: 70%;
  margin: 0.9rem auto;
}

.product {
  max-width: 320px;
  width: 100%;
  height: auto;
  position: relative;
  padding: 1.2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  border: 1px solid rgb(223, 220, 220);
  border-radius: 20px;
  margin: 0 10px;
}

.product-content {
  background: var(--gray);
  padding: 1rem 0.5rem;
  cursor: pointer;
}

.tampro {
  max-width: 180px;
  margin: auto;
  margin-bottom: 20px;
}

.product-img {
  background: var(--white-light);
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 50%;
  transition: background 0.5s ease;
}

.product-btns {
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin-top: 1.4rem;
  opacity: 1;
  transition: opacity 0.6s ease;
}

.btn-cart,
.btn-buy {
  border-radius: 5px;
  background: transparent;
  border: 1px solid black;
  padding: 0.8rem 0;
  margin: 1px;
  width: 100%;
  font-family: inherit;
  text-transform: none;
  cursor: pointer;
  border: none;
  transition: all 0.6s ease;
  display: block;
}

.btn-cart {
  background: var(--color-principal);
  color: white;
  font-weight: bold;
}

.btn-buy {
  background-color: var(--color-principal);
  color: white;
  font-weight: bold;
}

.btn-cart:hover {
  background: var(--color-principal);
}

.btn-buy:hover {
  background: orangered;
}

.product-info {
  background: var(--alice-blue);
  padding: 1rem;
  display: table;
  width: 100%;
}

.product-info-content {
  display: table-cell;
}

.product-info-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rating span {
  color: var(--carribean-green);
}

.product-sku {
  color: gray;
  font-weight: normal;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  display: block;
  font-size: 1em;
}

.product-price-old {
  padding-top: 0.6rem;
  padding-right: 0.6rem;
  display: inline-block;
  text-decoration: line-through;

  font-weight: bold;
  font-size: 1em;
  color: gray;
}

.product-price {
  padding-top: 0.6rem;
  /* padding-bottom: 0.6rem; */
  display: block;

  font-weight: bold;
  font-size: 1.3em;
  color: black;
}

.product-price-old-text {
  color: indigo;
  font-weight: bold;
  padding-top: 0.6rem;
  padding-right: 0.6rem;
  display: inline-block;
  font-size: 0.8em;
}

.product:hover .product-btns {
  opacity: 1;
}

.off-info .sm-title {
  background: orangered;
  color: white;
  display: inline-block;
  padding: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  letter-spacing: 3px;
  cursor: pointer;
}

/* product collection */
.product-collection {
  padding: 3.2rem 0;
}

.product-collection-wrapper {
  padding: 3.2rem 0;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 50vh;
  padding: 2rem 1.5rem 3.2rem;
  margin: 5px;
}

.product-col-r-bottom>div:first-child {
  background: #eaa001;
}

.product-col-r-bottom>div:last-child {
  background: var(--color-principal);
}

.product-col-content {
  text-align: center;
  color: white;
}

.product-collection .text-light {
  opacity: 1;
  font-size: 0.8;
  font-weight: 400;
  line-height: 1.7;
}

.btn-dark {
  background: black;
  color: white;
  outline: 0;
  border-radius: 25px;
  padding: 0.7rem 1rem;
  border: 0;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.6s ease;
  font-size: 1rem;
  font-family: inherit;
}

.btn-dark:hover {
  background: var(--carribean-green);
}

.nodata {
  color: var(--color-principal);
  text-align: center;
  position: sticky;
  top: 50%;
}

.nodataContent {
  min-height: 200px;
}

.product-card-price,
.product-card-nombre {
  width: 100%;
}

.tamprecio {
  font-weight: 600;
}

.product-name {
  font-weight: 400;
}

.tamprecio,
.product-name {
  display: inline;
  color: rgb(2, 2, 2);
  text-decoration: none;
  text-align: center;
  font-size: 1rem;
  font-family:
    Open Sans,
    sans-serif;
}

.mycart3 {
  margin-top: 0%;
}

.mycart4 {
  margin-top: 0%;
}

/* Media Queries */
@media screen and (min-width: 100px) {
  .space {
    margin-top: -4%;
  }

  .bucartMob {
    margin-top: 6%;
    margin-left: -4%;
    display: flex;
  }

  .myact {
    color: #b11313;
    width: 10px;
    height: 10px;
  }

  .active2 {
    width: 10px;
    height: 20px;
    color: #069435;
  }

  .icon {
    width: 10px;
    height: 10px;
  }

  .add {
    margin-left: 1%;
    margin-top: 4.5%;
  }

  .addMob {
    margin-left: 1%;
    margin-top: 5.3%;
  }

  .tagmob {
    margin-top: 66%;
  }

  .productargeta {
    margin: 5%;
    text-align: center;
    margin-left: 2%;

    position: relative;
    height: 370px;
    width: 90%;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
    zoom: 80%;
  }

  .variant2 {
    margin-top: -2%;
    display: flex;
    padding-left: 5%;
  }

  .variant {
    margin-top: -38%;
    display: flex;
    padding-left: 8%;
  }

  .anterior {
    margin-right: 10%;
    width: 100%;
    font-size: 8px;
  }

  .preciomobt {
    margin-right: 10%;
    width: 100%;
    font-size: 10px;
  }

  .tampro {
    height: 100%;
    display: block;
    margin-top: -10%;
  }

  .tag {
    background-color: #ce4040;
    width: 30%;
    margin-top: -10%;
    height: 4%;
  }

  .fou {
    color: #fff;
    font-size: 10px;
  }

  .product4 {
    margin-top: -15%;
  }

  .product3 {
    margin-top: -1%;
  }

  .prothem {
    margin-top: 0%;
  }

  .mycart3 {
    margin-top: 9%;
  }

  .mycart4 {
    margin-top: 4%;
  }

  .product2 {
    text-align: center;
    position: relative;
    height: 90%;
    width: 90%;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }

  .productmobile {
    text-align: center;
    position: relative;
    height: 255px;
    width: 150px;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }

  .coleccionMobile {
    width: 100%;
    height: 100%;
    margin-top: 5%;
    padding-top: 65%;
  }

  .tamprecio,
  .product-name {
    text-align: left;
    font-size: 0.8rem;
  }

  .esp {
    margin-top: -45%;
  }

  .product-items {
    max-width: 400px;
    width: 110%;
    display: grid;
    margin-right: 0%;
    grid-template-columns: repeat(2, 1fr);
  }

  .product-content {
    background: var(--gray);
    /* padding: 0%; */
    cursor: pointer;
    /* margin-top: -15%; */
  }

  .product-image {
    position: relative;
    top: 60%;
    transform: translateY(-50%);
    max-width: 150px;
    max-height: 150px;
    width: 100%;
    height: 100%;
    display: block !important;
    margin: auto !important;
  }

  .product-img {
    background: var(--white-light);
    width: 100%;
    height: 150px;
    margin: 0 auto;
    border-radius: 100%;
    transition: background 0.5s ease;
  }

  .product-info {
    background: var(--alice-blue);
    padding: 1rem;
    display: table;
    width: 100%;
  }

  .product-info-content {
    display: table-cell;
  }

  .product-info-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .product-sku {
    color: gray;
    font-weight: normal;
    padding-top: 20%;
    padding-bottom: 1%;
    display: block;
    font-size: 1em;
  }

  .pdnames {
    color: rgb(2, 2, 2);
    display: block;
    text-decoration: none;
    text-align: left;
    padding-left: 5%;
    width: 90%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 8px;
    font-family:
      Open Sans,
      sans-serif;
    margin-top: -15%;
  }

  .product-namemob {
    color: rgb(2, 2, 2);
    text-decoration: none;
    text-align: left;
    padding-left: 1%;
    width: 130px;
    height: 10%;
    margin: 10%;
    letter-spacing: 1px;
    font-size: 15px;
    font-family:
      Open Sans,
      sans-serif;
    margin-top: 0%;
  }

  .product-price {
    padding-top: 0.6rem;
    display: flex;
    font-weight: bold;
    font-size: 1.3em;
    color: black;
  }

  .product-btns {
    text-decoration: none;
    display: flex;
    justify-content: center;
    margin-top: 1.4rem;
    opacity: 1;
    transition: opacity 0.6s ease;
  }
}

@media screen and (min-width: 750px) {
  .space {
    margin-top: 15%;
  }

  .tampro {
    width: 30%;
    height: 100%;
    display: block;
    text-align: left;
    margin-top: -10%;
  }

  .bucartMob {
    margin-top: 10%;
    margin-left: 6%;
    display: flex;
  }

  .myact {
    color: #b11313;
    width: 10px;
    height: 10px;
  }

  .active2 {
    width: 10px;
    height: 10px;
    color: #069435;
  }

  .icon {
    width: 10px;
    height: 10px;
  }

  .add {
    margin-left: 1%;
    margin-top: 2%;
  }

  .addMob {
    margin-left: 1%;
    margin-top: 2%;
  }

  .tagh {
    background-color: #ce4040;
    width: 15%;
    margin-top: 1%;
  }

  .tagmob {
    margin-top: 130%;
  }

  .productargeta {
    text-align: center;
    margin-left: 2%;

    position: relative;
    height: 470px;
    width: 90%;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
    zoom: 80%;
  }

  .pdnames {
    color: rgb(2, 2, 2);
    display: block;
    text-decoration: none;
    text-align: left;
    padding-left: 5%;
    width: 90%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 18px;
    font-family:
      Open Sans,
      sans-serif;
    margin-top: -5%;
  }

  .product3 {
    margin-top: -1%;
  }

  .tag {
    background-color: #ce4040;
    width: 15%;
    height: 4%;
  }

  .fou {
    color: #fff;
    font-size: 18px;
  }

  .anterior {
    margin-left: 40%;
    width: 120%;
    font-size: 120%;
    height: 20px;
  }

  .preciomobt {
    margin-right: 10%;
    width: 100%;
  }

  .variant {
    margin-top: -8%;
    display: flex;
    padding-left: 8%;
  }

  .variant2 {
    margin-top: -2%;
    display: flex;
    padding-left: 5%;
  }

  .esp {
    margin-top: -60%;
  }

  .coleccionMobile {
    width: 100%;
    height: 100%;
    margin-top: 5%;
    padding-top: 65%;
  }

  .product-items {
    max-width: 700px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .product-namemob {
    color: rgb(2, 2, 2);
    text-decoration: none;
    text-align: left;
    padding-left: 5%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 24px;
    font-family:
      Open Sans,
      sans-serif;
  }

  .product-namemob {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    padding-left: 1%;
    width: 130px;
    height: 10%;
    margin: 10%;

    letter-spacing: 1px;

    font-size: 15px;
    font-family:
      Open Sans,
      sans-serif;
    margin-top: 0%;
  }

  .tamprecio,
  .product-name {
    text-align: left;
    font-size: 1rem;
  }

  .itm {
    margin: auto;
    justify-content: center;
  }

  .prothem {
    margin-top: 10%;
  }

  .product2 {
    text-align: center;
    position: relative;
    height: 90%;
    width: 90%;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }

  .productmobile {
    text-align: center;
    position: relative;
    height: 90%;
    width: 90%;
    border-width: 1%;
    border-style: solid;
    border-radius: 3%;
    border-color: rgb(223, 220, 220);
    zoom: 80%;
  }

  .mycart3 {
    margin-top: 7%;
  }

  .mycart4 {
    margin-top: 0%;
  }

  .product-col-r-bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 992px) {
  .space {
    margin-top: -4%;
  }

  .bucartMob {
    margin-top: 6%;
    margin-left: 5%;
    display: flex;
  }

  .myact {
    color: #b11313;
    width: 10px;
    height: 10px;
  }

  .active2 {
    width: 10px;
    height: 10px;
    color: #069435;
  }

  .icon {
    width: 10px;
    height: 10px;
  }

  .add {
    margin-left: 1%;
    margin-top: 4%;
  }

  .addMob {
    margin-left: 1%;
    margin-top: 4%;
  }

  .tagmob {
    margin-top: 75%;
  }

  .productargeta {
    text-align: center;
    position: relative;
    height: 470px;
    width: 90%;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    margin-left: 2%;

    border-color: rgb(223, 220, 220);
    zoom: 80%;
  }

  .tag {
    background-color: #ce4040;
    width: 15%;
    height: 4%;
  }

  .fou {
    color: #fff;
    font-size: 18px;
  }

  .anterior {
    margin-left: 20%;
    width: 120%;
    font-size: 18px;
  }

  .preciomobt {
    margin-right: 10%;
    width: 100%;
  }

  .variant {
    margin-top: -18%;
    display: flex;
    padding-left: 8%;
  }

  .variant2 {
    margin-top: -2%;
    display: flex;
    padding-left: 5%;
  }

  .esp {
    margin-top: -60%;
  }

  .product-namemob {
    color: rgb(2, 2, 2);
    text-decoration: none;
    text-align: left;
    padding-left: 5%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 24px;
    font-family:
      Open Sans,
      sans-serif;
  }

  .coleccionMobile {
    width: 100%;
    height: 100%;
    margin-top: 5%;
    padding-top: 65%;
  }

  .product-items {
    display: grid;
    max-width: 800px;
    grid-template-columns: repeat(3, 1fr);
  }

  .product-col-r-bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .tamprecio,
  .product-name {
    text-align: left;
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 912px) {
  .space {
    margin-top: -4%;
  }

  .bucartMob {
    margin-top: 16%;
    margin-left: 5%;
    display: flex;
  }

  .myact {
    color: #b11313;
    width: 10px;
    height: 10px;
  }

  .active2 {
    width: 10px;
    height: 10px;
    color: #069435;
  }

  .icon {
    width: 10px;
    height: 10px;
  }

  .add {
    margin-left: 1%;
    margin-top: 2%;
  }

  .addMob {
    margin-left: 1%;
    margin-top: 2%;
  }

  .tampro {
    width: 30%;
    height: 100%;
    display: block;
    text-align: left;
    margin-top: 8%;
  }
}

@media screen and (min-width: 1000px) {
  .space {
    margin-top: 5%;
  }

  .bucartMob {
    margin-top: 8%;
    margin-left: 25%;
    display: flex;
  }

  .myact {
    color: #b11313;
    width: 20px;
    height: 20px;
  }

  .active2 {
    width: 20px;
    height: 20px;
    color: #069435;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .add {
    margin-left: 1%;
    margin-top: 3%;
  }

  .addMob {
    margin-left: 1%;
    margin-top: 3%;
  }

  .tampro {
    width: 50%;
    height: 100%;
    display: block;
    text-align: left;
    margin-top: 8%;
  }
}

@media screen and (min-width: 1200px) {

  .bucartMob {
    margin-top: 8%;
    margin-left: 30%;
    display: flex;
  }

  .myact {
    color: #b11313;
    width: 20px;
    height: 20px;
  }

  .active2 {
    width: 20px;
    height: 20px;
    color: #069435;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .add {
    margin-left: 1%;
    margin-top: 3%;
  }

  .addMob {
    margin-left: 1%;
    margin-top: 3%;
  }

  .tagh {
    background-color: #ce4040;
    width: 15%;
    margin-top: 1%;
  }

  .space {
    margin-top: 5%;
  }

  .product3 {
    margin-top: 7%;
  }

  .tagmob {
    margin-top: 88%;
  }

  .productargeta {
    text-align: center;
    position: relative;
    height: 470px;
    width: 90%;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
    zoom: 80%;
    margin-left: 2%;
  }

  .tag {
    background-color: #ce4040;
    width: 15%;
    height: 4%;
  }

  .fou {
    color: #fff;
    font-size: 18px;
  }

  .anterior {
    margin-left: 8%;
    width: 120%;
    font-size: 18px;
  }

  .preciomobt {
    margin-right: 30%;
    width: 120%;
    margin-top: -6%;
    font-size: 18px;
  }

  .variant {
    margin-top: 0%;
    display: flex;
    padding-left: 8%;
  }

  .variant2 {
    margin-top: -2%;
    display: flex;
    padding-left: 5%;
  }

  .product-namemob {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    padding-left: 5%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 24px;
    font-family:
      Open Sans,
      sans-serif;
  }

  .esp {
    margin-top: -60%;
  }

  .coleccionMobile {
    width: 100%;
    height: 100%;
    margin-top: 5%;
    padding-top: 65%;
  }

  .product-items {
    grid-template-columns: repeat(3, 1fr);
    max-width: 1200px;
    padding-bottom: 100px;
  }

  .product2 {
    text-align: center;
    position: relative;
    height: 90%;
    width: 90%;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }

  .productmobile {
    text-align: center;
    position: relative;
    height: 90%;
    width: 90%;
    border-width: 1%;
    border-style: solid;
    border-radius: 3%;
    border-color: rgb(223, 220, 220);
    zoom: 80%;
  }

  .products .text-light {
    width: 50%;
  }

  .tamprecio,
  .product-name {
    text-align: left;
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1336px) {
  .space {
    margin-top: 6%;
  }

  .myact {
    color: #b11313;
    width: 20px;
    height: 20px;
  }

  .active2 {
    width: 20px;
    height: 25px;
    color: #069435;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .bucartMob {
    margin-top: 6%;
    margin-left: 30%;
    display: flex;
  }

  .add {
    margin-left: 1%;
    margin-top: 2%;
  }

  .addMob {
    margin-left: 1%;
    margin-top: 2%;
  }

  .product-content {
    background: var(--gray);
    cursor: pointer;
  }

  .product-namemob {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    padding-left: 5%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 24px;
    font-family:
      Open Sans,
      sans-serif;
  }

  .coleccionMobile {
    width: 100%;
    height: 100%;
    margin-top: 5%;
    padding-top: 65%;
  }

  .esp {
    margin-top: -30%;
  }

  .product-items {
    grid-template-columns: repeat(4, 1fr);
    max-width: 1330px;
    margin-right: 5%;
  }

  .product-collection-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .flex {
    height: 60vh;
  }

  .product-col-left {
    height: 121.5vh;
  }

  .pdnames {
    color: rgb(2, 2, 2);
    display: block;
    text-decoration: none;
    text-align: left;
    padding-left: 10%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 24px;
    font-family:
      Open Sans,
      sans-serif;
    margin-top: 0%;
  }

  .tamprecio,
  .product-name {
    text-align: left;
    font-size: 1.2rem;
  }

  .itm {
    margin: auto;
    justify-content: center;
  }

  .prothem {
    margin-top: 10%;
  }

  .product2 {
    text-align: center;
    position: relative;
    height: 90%;
    width: 90%;
    border-width: 1%;
    border-style: solid;
    border-radius: 3%;
    border-color: rgb(223, 220, 220);
    zoom: 80%;
  }

  .productmobile {
    text-align: center;
    position: relative;
    height: 90%;
    width: 95%;
    border-width: 1%;
    border-style: solid;
    border-radius: 3%;
    border-color: rgb(223, 220, 220);
    zoom: 80%;
  }

  .productargeta {
    text-align: center;
    position: relative;
    height: 470px;
    width: 90%;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
    zoom: 80%;

    margin-left: 20%;
  }

  .product4 {
    margin-top: 0%;
  }

  .product3 {
    margin-top: 4%;
    text-align: left;
    display: flex;
    width: 150%;
    height: 35px;
  }

  .mycart3 {
    margin-top: 8%;
  }

  .mycart4 {
    margin-top: 9%;
  }

  .side-bar .side-box {
    float: left;
    width: 100%;
    margin-bottom: 30px;
    border-radius: 5px;
  }

  .side-bar .box-heading {
    color: #000;
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    margin-block: 5px;
    text-transform: capitalize;
    padding: 10px 15px;
    position: relative;
    margin-block-start: 0;
    background-color: rgb(228, 220, 220);
    letter-spacing: 0.2px;
  }

  .side-bar .box-content {
    float: left;
    width: 100%;
    position: relative;
    margin: 5px 0 0;
    padding: 5px 20px 10px;
  }

  .tag {
    background-color: #ce4040;
    width: 15%;
    margin-top: -10%;
    height: 4%;
  }

  .tagh {
    background-color: #ce4040;
    width: 15%;
    margin-top: -1%;
  }

  .tagmob {
    margin-top: 100%;
  }

  .fou {
    color: #fff;
    font-size: 18px;
    margin-top: 10%;
  }

  .tampro {
    width: 60%;
    height: 100%;
    display: block;
    text-align: left;
  }

  .anterior {
    margin-left: -25%;
    width: 120%;
    font-size: 18px;
  }

  .variant {
    margin-top: -18%;
    display: flex;
    padding-left: 8%;
  }

  .variant2 {
    margin-top: -2%;
    display: flex;
    padding-left: 5%;
  }

  .preciomobt {
    margin-right: 30%;
    width: 120%;
    margin-top: 0%;
    font-size: 18px;
  }
}

/* Fin Card */
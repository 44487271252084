.contenedor {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 100%;
}
.contenedor2 {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 100%;
  zoom: 200%;
}

.contenedor3 {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 100%;
  zoom: 200%;
}
.texto-encima {
  position: absolute;
  top: 10px;
  left: 10px;
}

.centrado {
  position: absolute;
  top: 90%;
  left: 50%;
  color: rgb(250, 250, 250);
  font-style: normal;
  font-size: 30px;
  transform: translate(-50%, -50%);
}
.centrado2 {
  position: absolute;
  top: 90%;
  left: 50%;
  color: rgb(250, 250, 250);
  font-style: normal;
  font-size: 12px;
  transform: translate(-50%, -50%);
}
.centrado3 {
  position: absolute;
  top: 90%;
  left: 50%;
  color: rgb(250, 250, 250);
  font-style: normal;
  font-size: 12px;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 500px) {
  .centrado {
    position: absolute;
    top: 90%;
    left: 50%;
    color: rgb(250, 250, 250);
    font-style: normal;
    font-size: 10px;
    transform: translate(-50%, -50%);
  }
  .centrado2 {
    position: absolute;
    top: 90%;
    left: 50%;
    color: rgb(250, 250, 250);
    font-style: normal;
    font-size: 10px;
    transform: translate(-50%, -50%);
  }
  .centrado3 {
    position: absolute;
    top: 90%;
    left: 50%;
    color: rgb(250, 250, 250);
    font-style: normal;
    font-size: 10px;
    transform: translate(-50%, -50%);
  }
}

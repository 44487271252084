.card {
  opacity: 0.5 !important;
}

.buscando {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 2.4rem !important;
  text-align: center;
  width: 100%;
}
.tittle {
  background-color: transparent;
  font-size: 28px;

  width: 100%;
  margin-right: 85%;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
}
.desk-banner {
  margin-top: 15px;
}
@media screen and (min-width: 100px) {
  .tittle {
    background-color: transparent;
    font-size: 16px;
    width: 100%;
    margin-right: 70%;
    display: flex;
    position: -webkit-sticky;
    position: sticky;
  }
}
@media screen and (min-width: 1336px) {
  .tittle {
    background-color: transparent;
    font-size: 28px;
    width: 100%;
    margin-right: 73%;
    display: flex;
    position: -webkit-sticky;
    position: sticky;
  }
}

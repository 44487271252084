.titleColeccion1 {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  font-family:
    Open Sans,
    sans-serif;
  letter-spacing: 1px;
  color: rgb(61, 59, 59);
}
.titleColeccion2 {
  text-align: center;
  font-size: 20px;
}
.ul li {
  text-transform: none;
  list-style: none;
  display: inline-block;
  padding: 0 2px !important;
}
.puntuacion {
  font-size: 140%;
}
@media screen and (min-width: 100px) {
  .puntuacion {
    font-size: 100%;
  }
  .titleColeccion1 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family:
      Open Sans,
      sans-serif;
    letter-spacing: 1px;
    color: rgb(61, 59, 59);
  }
  .titleColeccion2 {
    text-align: center;
    font-size: 15px;
  }
  .ul li {
    text-transform: none;
    list-style: none;
    display: inline-block;
    padding: 0 2px !important;
  }
}
@media screen and (min-width: 1336px) {
  .puntuacion {
    font-size: 140%;
  }
  .titleColeccion1 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family:
      Open Sans,
      sans-serif;
    letter-spacing: 1px;
    color: rgb(61, 59, 59);
  }
  .ul li {
    text-transform: none;
    list-style: none;
    display: inline-block;
    padding: 0 2px !important;
  }
  .titleColeccion2 {
    text-align: center;
    font-size: 15px;
  }
}

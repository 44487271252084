.section {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 50px;
}

.ventajas {
  color: #051b4e;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  max-width: 650px;
  width: 100%;
  margin: 20px;
}

.list-int {
  justify-content: center;
  height: 249px;
  color: white;
  max-width: 300px;
  width: 100%;
  padding: 10px;
  background-color: #003462;
  line-height: 30px;
  border-radius: 5px;
}

.centered {
  font-size: 18px;
  width: 100%;
  text-align: center;
  color: white;
}

.pointer li {
  display: list-item !important;
  font-weight: bold;
  font-size: 15px;
}

.pointer {
  list-style-type: circle !important;
  list-style: square;
}

.ventajas-title {
  margin-bottom: 10px;
  font-size: 30px;
}

.MuiSvgIcon-root.pedido-detalle-icon {
  fill: white !important;
}

.pedido-detalle-stepper
  .MuiTypography-root.MuiStepLabel-label.MuiStepLabel-alternativeLabel.MuiTypography-body2.MuiTypography-displayBlock {
  margin-top: 5px !important;
}

.MuiTableCell-root {
  padding: 8px !important;
}

.MuiTableCell-root.table-no-padding {
  padding: 0px !important;
  border: none;
}

.MuiTableCell-root.table-bottom {
  padding: 0px !important;
}

i.icon {
  margin: 0 !important;
  height: unset !important;
}

i.icon.warning-icon {
  margin-left: 10px !important;
}

/* .ant-image-preview-mask {
  z-index: 9000 !important;
}

.ant-image-preview-wrap {
  z-index: 9080 !important;
}

.ant-image-preview-img-wrapper::before {
  height: 0 !important;
  display: flex !important;
}

.ant-image-preview-operations {
  background: rgba(0, 0, 0, 0.5) !important;
} */

.MuiTable-root {
  min-width: 200px !important;
}
.icons {
  fill: #ffffff !important;
}

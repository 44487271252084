.banner {
  display: flex;
}
.ofer {
  display: flex;
  margin-left: 5%;
}
.mydat {
  margin-left: 7%;
  color: red;
}
.offerclock {
  border: 1px solid rgb(155, 5, 5);
  border-radius: 5px;
  position: absolute;
  right: 110px;
  top: 0px;
}
.stars {
  margin-left: 36%;
  margin-top: -5%;
}
.priceA {
  color: rgb(2, 2, 2);
  /* display: block; */
  text-decoration: none;
  text-align: left;
  margin-left: 40%;
  width: 100%;
  letter-spacing: 1px;
  height: 30%;
  font-size: 70%;
  font-family:
    Open Sans,
    sans-serif;
}
.contenido {
  margin-top: -12%;
}
.descripcion {
  color: rgb(2, 2, 2);
  /* display: block; */
  text-decoration: none;
  text-align: left;
  margin-left: 40%;
  width: 60%;
  letter-spacing: 1px;
  height: 30%;
  font-size: 70%;
  font-family:
    Open Sans,
    sans-serif;
}
.price {
  color: rgb(2, 2, 2);
  /* display: block; */
  text-decoration: none;
  text-align: left;
  margin-left: 40%;
  width: 100%;
  letter-spacing: 1px;
  height: 30%;
  font-size: 70%;
  font-weight: bold;
  font-family:
    Open Sans,
    sans-serif;
}
.name {
  color: rgb(2, 2, 2);
  display: block;
  text-decoration: none;
  text-align: left;
  padding-left: 5%;
  width: 100%;
  letter-spacing: 1px;
  height: 30%;
  font-size: 70%;
  font-family:
    Open Sans,
    sans-serif;
}
.ofertaimg {
  width: 6%;
  height: 50%;
  margin-top: 0%;
  margin-left: 10%;
}
.letras {
  margin-bottom: 12px;
  margin-top: -27%;

  font-weight: 600;
  color: #4b4b4b;
  width: 30%;
  line-height: 1;
}
.dentro {
  position: relative;
  display: inline-block;
}
.suscribe {
  display: flex;
  margin: auto;
}
.parrafos {
  width: 50%;
  font-size: 26px;
  margin-left: 60%;
}
.parrafoImagen {
  width: 70%;
}
.titulosList {
  text-align: center;
}
.parrafosTitle {
  font-size: 25px;
  margin-left: 60%;
}
.back {
  margin-left: 0%;
}
.contenedorInf {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 100%;
}
.centradoInf {
  position: absolute;
  top: 90%;
  left: 50%;
  color: rgb(250, 250, 250);
  font-style: normal;
  font-size: 30px;
  transform: translate(-50%, -50%);
}
.parrafosFecha {
  font-size: 25px;
  margin-left: 60%;
  color: var(--color-principal);
}
.boxparrafo {
  float: left;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 5px;
}
.img0 {
  filter: var(--colorima0);
}
.img1 {
  filter: var(--colorima1);
}
.img2 {
  filter: var(--colorima2);
}
.img3 {
  filter: var(--colorima3);
}
.img4 {
  filter: var(--colorima4);
}
.img5 {
  filter: var(--colorima5);
}
.img6 {
  filter: var(--colorima6);
}
.img7 {
  filter: var(--colorima7);
}
.img8 {
  filter: var(--colorima8);
}
.img9 {
  filter: var(--colorima9);
}
.img10 {
  filter: var(--colorima10);
}
a:hover {
  color: #1a1d1b;
  cursor: pointer;
}
.a {
  text-decoration: none !important;
}
.img11 {
  filter: var(--colorima11);
}
.img12 {
  filter: var(--colorima12);
}
.img13 {
  filter: var(--colorima13);
}
.img14 {
  filter: var(--colorima14);
}
.img15 {
  filter: var(--colorima15);
}
.img16 {
  filter: var(--colorima16);
}
.img17 {
  filter: var(--colorima17);
}
.img18 {
  filter: var(--colorima18);
}
.img19 {
  filter: var(--colorima19);
}
.img20 {
  filter: var(--colorima20);
}
.img21 {
  filter: var(--colorima21);
}
.img22 {
  filter: var(--colorima22);
}
.img23 {
  filter: var(--colorima23);
}
.img24 {
  filter: var(--colorima24);
}
.img25 {
  filter: var(--colorima25);
}
.img26 {
  filter: var(--colorima26);
}
.img27 {
  filter: var(--colorima27);
}
.img28 {
  filter: var(--colorima28);
}
.img29 {
  filter: var(--colorima29);
}
.img30 {
  filter: var(--colorima30);
}
.img31 {
  filter: var(--colorima31);
}
.box {
  background: #e9e6e6;
}
.ver {
  display: flex;
  margin-left: 0%;
}
.imagen {
  width: 40%;
  height: 70%;
}
.imagenes {
  width: 32%;
  height: 20%;
  margin-top: 0%;
}
.letra {
  margin-bottom: 12px;
  margin-top: -27%;

  font-weight: 600;
  color: #4b4b4b;
  width: 30%;
  line-height: 1;
}
.tam {
  width: 100%;
  height: 20%;
  margin: 15px;
}
.but {
  margin-left: 0%;
}
.subt {
  font-size: 200%;
  width: 65%;
}
.alt {
  margin-top: 0%;
}
.letra {
  text-align: center;
}
.imagenes {
  width: 28%;
  height: 20%;
  margin-left: 50%;
}
.content {
  margin: auto;
}
.caru {
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  border-color: rgb(223, 220, 220);
}
.myimg {
  width: 100%;
}

.mytitulo {
  color: rgb(2, 2, 2);
  /* display: block; */
  text-decoration: none;
  text-align: left;

  letter-spacing: 1px;
  width: 10%;

  font-size: 24px;

  font-family:
    Open Sans,
    sans-serif;
}
.myfecha {
  color: var(--color-principal);
  /* display: block; */
  text-decoration: none;

  font-weight: bold;
  margin-left: 32%;
  letter-spacing: 1px;

  font-size: 24px;
  font-family:
    Open Sans,
    sans-serif;
}
.caru {
  width: 100%;
  height: 98%;

  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  border-color: rgb(223, 220, 220);
}

.caru2 {
  padding-left: 5%;
  padding-right: 5%;
}
.titleColeccion1 {
  text-align: center;
  font-weight: bold;
  font-size: 27px;
  font-family:
    Open Sans,
    sans-serif;
  letter-spacing: 1px;
  color: rgb(61, 59, 59);
}
.carus {
  width: 95%;
  height: 98%;

  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  border-color: rgb(223, 220, 220);
}
@media screen and (min-width: 100px) {
  .centradoInf {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 60%;
    color: rgb(250, 250, 250);
    font-style: normal;
    font-size: 30px;
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: 300px) {
  .alt {
    margin-top: -1%;
    margin-left: 5%;
  }
  .letra {
    margin-bottom: 12px;
    margin-top: -25%;
    font-size: 2px !important;
    font-weight: 600;
    color: #4b4b4b;
    width: 30%;
    height: 100%;
    line-height: 1;
  }
  .titleColeccion1 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family:
      Open Sans,
      sans-serif;
    letter-spacing: 1px;
    color: rgb(61, 59, 59);
  }
  .subt {
    font-size: 180%;
    margin-top: -1%;
    display: block;
    width: 100%;
    margin-left: 30%;
  }
  .subt2 {
    font-size: 95%;
    margin-top: 5%;
    display: block;
    margin-left: 30%;
  }
  .ver {
    display: block;
    margin-left: 2%;
  }
  .but {
    margin-left: 3%;
  }
  .tam {
    width: 90%;
    height: 100%;
  }
  .imagenes {
    width: 28%;
    height: 20%;
    margin-left: 30%;
  }
}
@media screen and (min-width: 768px) {
  .carus {
    width: 100%;
    height: 98%;

    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }
  .letras {
    margin-bottom: 12px;
    margin-top: -27%;

    font-weight: 600;
    color: #4b4b4b;
    width: 30%;
    line-height: 1;
  }
  .stars {
    margin-left: 39%;
    margin-top: -2%;
  }
  .offerclock {
    border: 1px solid rgb(155, 5, 5);
    border-radius: 5px;
    position: absolute;
    right: 110px;
    top: 0px;
  }
  .ofertaimg {
    width: 20%;
    height: 25%;
    margin-left: 5%;
    margin-top: auto;
  }
  .contenido {
    margin-top: -20%;
  }
  .name {
    color: rgb(2, 2, 2);
    display: block;
    text-decoration: none;
    text-align: left;
    margin-left: 34%;
    width: 70%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 15px;
    font-family:
      Open Sans,
      sans-serif;
  }
  .descripcion {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    margin-left: 40%;
    width: 50%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 14px;
    font-family:
      Open Sans,
      sans-serif;
  }
  .price {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    margin-left: 40%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 12px;
    font-weight: bold;
    font-family:
      Open Sans,
      sans-serif;
  }
  .priceA {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    margin-left: 40%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 12px;

    font-family:
      Open Sans,
      sans-serif;
  }
  .contenedorInf {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 100%;
  }
  .centradoInf {
    display: flex;
    width: 90%;
    margin-top: 0%;
  }
  .back {
    margin-left: 17%;
    font-size: 14px;
  }
  .parrafos {
    width: 70%;
    font-size: 18px;
    margin-left: 17%;
    margin-top: 0%;
  }
  .parrafosTitle {
    font-weight: bold;

    font-size: 22px;
    margin-top: 0%;

    margin-left: 18%;
  }
  .a {
    text-decoration: none !important;
  }
  .parrafosFecha {
    font-size: 23px;
    margin-left: 17%;
    margin-top: 0%;

    color: var(--color-principal);
  }
  .parrafoImagen {
    width: 70%;
    margin-left: 17%;
    margin-top: 0%;
  }
  .boxparrafo {
    border-width: 1px;
    width: 102%;
    border-style: solid;
    border-radius: 5px;
    border-color: rgb(180, 178, 178);
    margin-left: -1%;
    margin-top: 0%;
  }
  .titulosList {
    font-size: 20px;
    color: var(--color-principal);
    text-align: left;
  }
  a:hover {
    color: #1a1d1b;
    cursor: pointer;
  }
  .textoparraf {
    margin-top: 6%;
    visibility: hidden;
    font-size: 17px;

    margin-left: 2%;

    background-color: rgb(209, 207, 207);
    width: 20%;
    border-style: solid;
    border-radius: 5px;
    border-color: rgb(209, 207, 207);
  }
  .banner {
    display: block;
    margin-right: 30%;
    text-align: center !important;
  }
  .alt {
    margin-top: -12%;
    margin-left: -3%;
  }
  .letra {
    margin-bottom: 12px;
    margin-top: -25%;
    font-size: 2px !important;
    font-weight: 600;
    color: #4b4b4b;
    width: 30%;
    height: 100%;
    line-height: 1;
  }
  .titleColeccion1 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family:
      Open Sans,
      sans-serif;
    letter-spacing: 1px;
    color: rgb(61, 59, 59);
  }
  .subt {
    font-size: 180%;
    margin-top: -1%;
    display: block;
    width: 190%;
    margin-left: 30%;
  }
  .subt2 {
    font-size: 95%;
    margin-top: 5%;
    display: block;
    margin-left: 30%;
  }
  .ver {
    display: block;
    margin-left: 2%;
  }
  .but {
    margin-left: 3%;
  }
  .tam {
    width: 90%;
    height: 100%;
  }
  .imagenes {
    width: 28%;
    height: 20%;
    margin-left: 50%;
  }
  .content {
    margin: auto;
  }
  .caru {
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }
  .myimg {
    width: 100%;
  }
}
.mytitulo {
  color: rgb(2, 2, 2);
  /* display: block; */
  text-decoration: none;
  text-align: left;

  letter-spacing: 1px;
  width: 10%;

  font-size: 14px;

  font-family:
    Open Sans,
    sans-serif;
}
.myfecha {
  color: var(--color-principal);
  /* display: block; */
  text-decoration: none;

  font-weight: bold;
  margin-left: 25%;
  letter-spacing: 1px;

  font-size: 14px;
  font-family:
    Open Sans,
    sans-serif;
}
.caru {
  width: 100%;
  height: 98%;

  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  border-color: rgb(223, 220, 220);
}

.caru2 {
  padding-left: 5%;
  padding-right: 5%;
}
@media screen and (min-width: 1200px) {
  .alt {
    margin-top: -5%;
    margin-left: 2%;
  }
  .letras {
    color: #4b4b4b;
    width: 30%;
  }
  .subt2 {
    font-size: 95%;
    margin-top: 25%;
    display: block;
    margin-left: 50%;
  }
  .subt {
    font-size: 180%;
    margin-top: -5%;
    display: block;
    width: 100%;
    margin-left: 50%;
  }
}
@media screen and (min-width: 830px) {
  .carus {
    width: 100%;
    height: 98%;

    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }
  .letras {
    margin-bottom: 12px;
    margin-top: -27%;

    font-weight: 600;
    color: #4b4b4b;
    width: 30%;
    line-height: 1;
  }
  .stars {
    margin-left: 39%;
  }
  .offerclock {
    border: 1px solid rgb(155, 5, 5);
    border-radius: 5px;
    position: absolute;
    right: 110px;
    top: 0px;
  }
  .ofertaimg {
    width: 15%;
    height: 25%;
    margin-left: 5%;
    margin-top: auto;
  }
  .contenido {
    margin-top: -15%;
  }
  .name {
    color: rgb(2, 2, 2);
    display: block;
    text-decoration: none;
    text-align: left;
    margin-left: 34%;
    width: 70%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 15px;
    font-family:
      Open Sans,
      sans-serif;
  }
  .descripcion {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    margin-left: 40%;
    width: 50%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 14px;
    font-family:
      Open Sans,
      sans-serif;
  }
  .price {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    margin-left: 40%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 12px;
    font-weight: bold;
    font-family:
      Open Sans,
      sans-serif;
  }
  .priceA {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    margin-left: 40%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 12px;

    font-family:
      Open Sans,
      sans-serif;
  }
  .contenedorInf {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 100%;
  }
  .centradoInf {
    display: flex;
    width: 90%;
    margin-top: 0%;
  }
  .back {
    margin-left: 17%;
    font-size: 14px;
  }
  .parrafos {
    width: 70%;
    font-size: 18px;
    margin-left: 17%;
    margin-top: 0%;
  }
  .parrafosTitle {
    font-weight: bold;

    font-size: 22px;
    margin-top: 0%;

    margin-left: 18%;
  }
  .a {
    text-decoration: none !important;
  }
  .parrafosFecha {
    font-size: 23px;
    margin-left: 17%;
    margin-top: 0%;

    color: var(--color-principal);
  }
  .parrafoImagen {
    width: 70%;
    margin-left: 17%;
    margin-top: 0%;
  }
  .boxparrafo {
    border-width: 1px;
    width: 102%;
    border-style: solid;
    border-radius: 5px;
    border-color: rgb(180, 178, 178);
    margin-left: -1%;
    margin-top: 0%;
  }
  .titulosList {
    font-size: 20px;
    color: var(--color-principal);
    text-align: left;
  }
  a:hover {
    color: #1a1d1b;
    cursor: pointer;
  }
  .textoparraf {
    margin-top: 6%;
    visibility: hidden;
    font-size: 17px;

    margin-left: 2%;

    background-color: rgb(209, 207, 207);
    width: 20%;
    border-style: solid;
    border-radius: 5px;
    border-color: rgb(209, 207, 207);
  }
  .banner {
    display: block;
    margin-right: 30%;
    text-align: center !important;
  }
  .alt {
    margin-top: -4%;
    margin-left: -3%;
  }
  .letra {
    margin-bottom: 12px;
    margin-top: -25%;
    font-size: 2px !important;
    font-weight: 600;
    color: #4b4b4b;
    width: 30%;
    height: 100%;
    line-height: 1;
  }
  .titleColeccion1 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family:
      Open Sans,
      sans-serif;
    letter-spacing: 1px;
    color: rgb(61, 59, 59);
  }
  .subt {
    font-size: 180%;
    margin-top: -1%;
    display: block;
    width: 80%;
    margin-left: 30%;
  }
  .subt2 {
    font-size: 95%;
    margin-top: 5%;
    display: block;
    margin-left: 30%;
  }
  .ver {
    display: flex;
    margin-left: 2%;
  }
  .but {
    margin-left: 3%;
  }
  .tam {
    width: 90%;
    height: 100%;
  }
  .imagenes {
    width: 28%;
    height: 20%;
    margin-left: 30%;
  }
  .content {
    margin: auto;
  }
  .caru {
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }
  .myimg {
    width: 100%;
  }
}
.mytitulo {
  color: rgb(2, 2, 2);
  /* display: block; */
  text-decoration: none;
  text-align: left;

  letter-spacing: 1px;
  width: 10%;

  font-size: 14px;

  font-family:
    Open Sans,
    sans-serif;
}
.myfecha {
  color: var(--color-principal);
  /* display: block; */
  text-decoration: none;

  font-weight: bold;
  margin-left: 25%;
  letter-spacing: 1px;

  font-size: 14px;
  font-family:
    Open Sans,
    sans-serif;
}
.caru {
  width: 100%;
  height: 98%;

  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  border-color: rgb(223, 220, 220);
}

.caru2 {
  padding-left: 5%;
  padding-right: 5%;
}
@media screen and (min-width: 1336px) {
  .alt {
    margin-top: -8%;
    margin-left: 0%;
  }
  .imagenes {
    width: 28%;
    height: 20%;
    margin-left: 40%;
  }
  .letras {
    color: #4b4b4b;
    width: 30%;
  }
  .ver {
    display: flex;
    margin-left: 2%;
  }
  .subt2 {
    font-size: 95%;
    margin-top: 5%;
    display: block;
    margin-left: 30%;
  }
  .subt {
    font-size: 180%;
    margin-top: -1%;
    display: block;
    width: 100%;
    margin-left: 30%;
  }
  .mydat {
    font-size: 22px;
    color: red;
  }
  .stars {
    margin-left: 40%;
  }
  .offerclock {
    font-size: 20px;
    color: red;
  }
  .ofertaimg {
    width: 25%;
    height: 35%;
    margin-left: 5%;
    margin-top: auto;
  }
  .contenido {
    margin-top: -10%;
  }
  .content {
    margin: auto;
  }
  .caru {
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }
  .myimg {
    width: 100%;
  }
  .ofer {
    display: flex;
    margin-left: 45%;
  }
  .mytitulo {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;

    letter-spacing: 1px;
    width: 10%;

    font-size: 24px;

    font-family:
      Open Sans,
      sans-serif;
  }
  .myfecha {
    color: var(--color-principal);
    /* display: block; */
    text-decoration: none;

    font-weight: bold;
    margin-left: 32%;
    letter-spacing: 1px;

    font-size: 24px;
    font-family:
      Open Sans,
      sans-serif;
  }
  .carus {
    width: 95%;
    height: 98%;

    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }
  .caru {
    width: 90%;
    height: 250px;
    margin-left: 3%;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    border-color: rgb(223, 220, 220);
  }

  .caru2 {
    padding-left: 5%;
    padding-right: 5%;
  }
  .titleColeccion1 {
    text-align: center;
    font-weight: bold;
    font-size: 27px;
    font-family:
      Open Sans,
      sans-serif;
    letter-spacing: 1px;
    color: rgb(61, 59, 59);
  }
  .parrafos {
    width: 70%;
    font-size: 20px;
    margin-left: 18%;
    margin-top: 0%;
  }
  .parrafosTitle {
    font-weight: bold;

    font-size: 25px;
    margin-top: 0%;

    margin-left: 18%;
  }

  .a {
    text-decoration: none !important;
  }
  .back {
    margin-left: 18%;
    font-size: 20px;
  }
  .parrafosFecha {
    font-size: 25px;
    margin-left: 17%;
    margin-top: 0%;

    color: var(--color-principal);
  }
  .parrafoImagen {
    width: 70%;
    margin-left: 17%;
    margin-top: 0%;
  }
  .boxparrafo {
    border-width: 1px;
    width: 102%;
    border-style: solid;
    border-radius: 5px;
    border-color: rgb(180, 178, 178);
    margin-left: -1%;
    margin-top: 0%;
  }
  .titulosList {
    font-size: 20px;
    color: var(--color-principal);
    text-align: left;
  }
  a:hover {
    color: #1a1d1b;
    cursor: pointer;
  }
  .textoparraf {
    margin-top: 6%;

    font-size: 17px;

    margin-left: 2%;

    background-color: rgb(209, 207, 207);
    width: 20%;
    border-style: solid;
    border-radius: 5px;
    border-color: rgb(209, 207, 207);
  }
  .contenedorInf {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 100%;
  }
  .centradoInf {
    display: flex;
    width: 50%;
    margin-top: -3%;
  }
  .name {
    color: rgb(2, 2, 2);
    display: block;
    text-decoration: none;
    text-align: left;
    margin-left: 35%;
    width: 70%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 20px;
    font-family:
      Open Sans,
      sans-serif;
  }
  .descripcion {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    margin-left: 40%;
    width: 60%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 18px;
    font-family:
      Open Sans,
      sans-serif;
  }
  .price {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    margin-left: 40%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 18px;
    font-weight: bold;
    font-family:
      Open Sans,
      sans-serif;
  }
  .priceA {
    color: rgb(2, 2, 2);
    /* display: block; */
    text-decoration: none;
    text-align: left;
    margin-left: 40%;
    width: 100%;
    letter-spacing: 1px;
    height: 30%;
    font-size: 15px;

    font-family:
      Open Sans,
      sans-serif;
  }
  .suscribe {
    display: flex;
    background-color: #fff;

    margin-top: -15%;
  }
  .dentro {
    height: 100%;
    width: 100%;
  }
}

.iconColor {
  color: var(--color-principal) !important;
}

.close-icon {
  text-decoration: none !important;
  list-style: none;
  color: black;
  cursor: pointer;
}
.menu-barscloseHead {
  text-decoration: none !important;
}
.a {
  text-decoration: none !important;
}

.menu-barscloseHead {
  width: 50%;
  text-align: end;
  color: rgb(212, 131, 131);
}

.cart-head {
  display: flex;
}

.title-head {
  width: 50%;
}
.MuiSvgIcon-root {
  fill: var(--color-principal) !important;
}
.MuiSvgIcon-navBar {
  fill: #ffffff !important;
}

.mobile-carousel .MuiSvgIcon-root {
  fill: white !important;
}

.mobile-carousel .MuiButtonBase-root {
  background-color: var(--color-principal) !important;
}
.card-cart-counter {
  margin-top: 10px;
  margin-bottom: 10px;
}
.gridAlign {
  text-align: right;
}
.nrow {
  display: flex;
  padding: 5px;
}
.separator {
  margin: 0px;
}
.line {
  margin: 30px;
  height: 1px;
}
.marginRow {
  margin-left: 90px;

  margin-right: 90px;
}
.marginGrid {
  float: right !important;
}
.marginBottom {
  margin: 0px, 20px, 0px, 20px;
}
.margin50 {
  margin-left: 5px;
  margin-right: 50px;
}
.marginImg {
  margin-left: 5px;
  margin-right: 30px;
}
.margin70 {
  margin-left: 70px;
  margin-right: 30px;
}
.marginCar {
  margin-left: 30px;
  margin-bottom: 10px;
  margin-top: 0px;
}
.marginIcon {
  margin-left: 10px;
  margin-top: 10px;
}
.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
}

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
}
.marginHeader {
  margin-top: 10%;
}
.text-bold {
  font-weight: bold !important;
}
button:disabled,
button[disabled] {
  background-color: #003462ab !important;
  cursor: no-drop;
}

.navbar {
  margin-top: 20px;
  background-color: transparent;
  margin-left: 5%;

  align-items: center;
  width: 100%;

  top: 0;
}

.tittle {
  margin-left: -800%;
}
.coleccion {
  margin-left: 5%;
}
.hola {
  color: red;
}

.menu-icon {
  background-color: transparent;
  list-style: square;
  color: transparent;
}

.menu-icon-filtro {
  background-color: transparent;
  list-style: none;
  margin-left: 20px;
}

.menu-bars {
  margin-top: 55px;
  font-size: 18px;
  list-style: none;
  display: flex;
  text-transform: none;
  background-color: white;
  /* background: transparent url("../svg/cd-icon-filter.svg") no-repeat center center; */
}

.menu-barsclose {
  width: 100%;
  text-align: end;
  color: var(--color-principal) !important;
  margin-top: -20px;
}

.menu-barsaplicar .app {
  padding: 5px;
  color: white;
  border-radius: 5px;
  background-color: #ffffff;
}

.menu-barsaplicar {
  width: 100%;
  text-align: start;
}

/* .menu-bars ::after{
  content: ('Filtro');
   } */

.ant-collapse-header {
  color: white;
  cursor: pointer;
  padding: 5px;
  font-size: 18px;
  font-weight: bold;
}

.anticon {
  margin-right: 10px;
}

.ant-collapse-item ant-collapse-item-active {
  border-color: transparent;
}

.ant-collapse-content {
  width: 100%;
}

.ant-collapse-content-inactive {
  display: none;
}

.ant-collapse-content-active {
  display: block;
}

.filtertext {
  color: #8f83b8;
  margin-left: 5px;
}

.nt-checkbox-input {
  margin-right: 15px;
}

.item-check {
  margin-right: 10px;
}

.check-item-cont {
  margin-left: 20px;
  display: flex;
  padding: 5px;
  cursor: pointer;
  color: white;
}

.nav-menu {
  background-color: white;
  width: 300px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 999;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  overflow: auto;
  border: gray;
  border-width: 1px;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #060b26;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  width: 100%;
}

ul li {
  text-transform: none;
  list-style: none;
  display: inline-block;
  padding: 0 15px;
}

.nav-menu.active {
  background-color: #ffffff;
  overflow-y: scroll;
  z-index: 999999;
  overflow: auto;
  border: gray;
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  width: 25%;
}

.navbar-toggle {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* span {
    margin-left: 16px;
  } */

/* @media screen and (max-width: 600px){
    .nav-menu {
      
      width: 80%;
      
     
    }
  
  } */
@media screen and (min-width: 1336px) {
  .tittle {
    margin-left: -800%;
  }
  ul li {
    text-transform: none;
    list-style: none;
    display: inline-block;
    padding: 0px;
  }
}
@media screen and (min-width: 100px) {
  .tittle {
    margin-left: 5%;

    background-color: transparent;
    white-space: pre;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
  ul li {
    text-transform: none;
    list-style: none;
    display: inline-block;
    padding: 0px;
  }
}

:root {
  --color-principal: red;
}
.MuiButton-containedPrimary {
  background-color: white;
  text-transform: none;
}

.login-desk .MuiButton-containedPrimary {
  background-color: var(--color-principal) !important;
  text-transform: none !important;
}

.login-mobile .MuiButton-containedPrimary {
  background-color: var(--color-principal) !important;
  text-transform: none !important;
  display: flex;
}
.ingre {
  margin-top: 13.3%;
}
.lo {
  display: flex;
}
/* Style The Dropdown Button */
.car {
  margin-top: 8.5%;
}
.dropbtn {
  background-color: white;
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown-container {
  display: inline-block;
}

/* The container <div> - needed to position the dropdown content */

.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */

.dropdown-content {
  display: none;
  position: fixed;
  text-align: justify !important;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  position: fixed;
  text-align: left !important;
  text-decoration: none;
  text-transform: capitalize;
  display: block;
}

/* Change color of dropdown links on hover */

.dropdown-content a:hover {
  background-color: white;
}

/* Show the dropdown menu on hover */

.dropdown:hover .dropdown-content {
  display: block;
  text-align: left !important;
}

/* Change the background color of the dropdown button when the dropdown content is shown */

.dropdown:hover .dropbtn {
  background-color: white;
  text-align: left !important;
}

/**/

/* Style The Dropdown Button */

.subdropbtn {
  background-color: white;
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.subdropdown-container {
  display: inline-block;
}

/* The container <div> - needed to position the dropdown content */

.subdropdown {
  margin-top: -4px;
  position: relative;
  /* display: inline-block; */
  width: 100%;
}

/* Dropdown Content (Hidden by Default) */

.subdropdown-content {
  display: none;
  position: absolute;
  text-align: justify !important;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */

.subdropdown-content a {
  color: black;
  padding: 12px 16px;
  position: fixed;
  text-align: left !important;
  text-decoration: none;
  text-transform: capitalize;
  display: block;
}

/* Change color of dropdown links on hover */

.subdropdown-content a:hover {
  background-color: white;
}

/* Show the dropdown menu on hover */

.subdropdown:hover .subdropdown-content {
  display: inline-grid;
  text-align: left !important;
}

/* Change the background color of the dropdown button when the dropdown content is shown */

.subdropdown:hover .subdropbtn {
  background-color: white;
  text-align: left !important;
}

.makeStyles-root-24 {
  background: var(--color-principal) !important;
}
.hed {
  width: 100%;
  text-align: center;
}
.all {
  width: 100%;
}
/* #54A59E  */
/* background: #003462; */

/* #54A59E  */
@media screen and (max-width: 500px) {
  .ingre {
    margin-left: 15%;
  }
}
@media screen and (max-width: 500px) {
  .hed {
    width: 100%;
    text-align: center;
  }
  .all {
    width: 100%;
  }
}

.letra {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 50px;
}
.t {
  display: block;
}
.formulario {
  background-color: rgb(253, 253, 253);
}
.caja {
  /* margin: 4em; */
  margin: 1em;
  padding: 1em;
  box-shadow: 1px 2px 9px #000;
}
.infor {
  padding-right: 30%;
  height: 200%;
  margin-left: 25%;
  margin-top: 0%;
}
.captcha {
  margin-left: 5%;
}
.e {
  padding-left: 0%;
  padding-top: 3%;
}
.clientes {
  font-size: 50%;
}
.banner {
  margin-left: 25%;
}
@media screen and (min-width: 500px) {
  .formulario {
    background-color: rgb(253, 253, 253);
    width: 50%;
    height: 50%;
    margin-top: 0%;
    margin-left: 50%;
  }
  .captcha {
    margin-left: 13%;
  }
  .infor {
    padding-right: 0%;
    width: 30%;
    margin-left: 12%;
    margin-top: 20%;
  }
  .caja {
    /* margin: 4em; */
    margin: 4em;
    padding: 1em;
    box-shadow: 1px 2px 9px #000;
    margin-top: -40%;
  }
  .banner {
    margin-left: 0%;
    width: 100%;
  }
  .clientes {
    font-size: 70%;
  }
  .letra {
    font-family: 'Titillium Web', sans-serif;
    font-weight: 300;
    font-size: 50%;
  }
  .t {
    width: 50%;
    text-align: justify;
    margin-left: 1.5%;
  }

  .e {
    padding-left: 2%;
    padding-top: 0%;
  }
}

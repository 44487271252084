.desk-banner {
  margin-top: 10px;
  width: 100%;
  height: 100%;
  color: black;
}

.carousel .control-dots .dot {
  border-radius: 0px !important;
}

.carousel.carousel-slider .control-arrow {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 !important;
  position: absolute !important;
  top: 50% !important;
  -ms-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
  bottom: auto !important;
  opacity: 1 !important;
  background-color: var(--color-principal) !important;
}

.carousel.carousel-slider .control-arrow :hover {
  background-color: var(--color-principal) !important;
  filter: none !important;
}

.Banner {
  height: 400px;
  position: relative;
}

.Banner .Media {
  background-color: rgb(236, 245, 240);
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: 300ms;
  cursor: pointer;
}

.Banner .Media .MediaCaption {
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0;
  padding: 15px;
  background-color: black;
  color: white;
  opacity: 0.6;
  width: 100%;
  height: 10%;
  font-weight: 200;
  transition: 300ms;
  cursor: pointer;
}

.Banner .Media .MediaCaption:hover {
  opacity: 0.8;
}

.Banner .Media:hover {
  filter: brightness(115%);
}

.Banner .BannerGrid {
  height: 100%;
  position: relative;
}

.Banner .Content {
  color: white;
  background-color: blue;
  height: 100%;
  position: relative;
  cursor: pointer;
  padding: 30px;
  transition: 300ms;
}

.Banner .Content:hover,
.Banner .Content:active {
  background-color: red-active;
}

.Banner .Content .Title {
  font-weight: 500;
}

.Banner .Content .Caption {
  margin-top: 10px;
}

.Banner .Content .ViewButton {
  margin-top: 40px;
  color: white;
  border: 3px solid white;
  text-transform: capitalize;
  transition: 200ms;
}

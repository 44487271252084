.react-search-field {
  width: 40%;
  border: #3f51b5 1px solid !important;
  border-radius: 10px;
}
.login-mobile {
  width: 100%;
}

.login-mobile .MuiButtonGroup-grouped {
  margin-left: auto;
  margin-right: 5%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.cart-mobile {
  margin-left: auto;
  margin-right: 0;
}

.cart-mobile .MuiBox-root {
  margin-right: 0px !important;
}

.react-search-field-input {
  background-color: transparent;
  color: #3f51b5 !important;
  font-weight: normal !important;
}

.react-search-field-input::placeholder {
  color: #3f51b5 !important;
}

.react-search-field-button {
  background-color: transparent !important;
  border-left: transparent 1px solid !important;
}

.MuiButton-containedPrimary {
  background-color: var(--color-principal) !important;
  text-transform: none !important;
}

.react-search-field-button :first-child {
  fill: #3f51b5;
}

@media only screen and (max-width: 900px) {
  .react-search-field {
    width: 90%;
    margin-left: 5%;
  }
}

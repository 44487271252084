.select-cat {
  width: 300px;
  border: var(--color-principal) 1px solid !important;
  border-radius: 5px !important;
}

.icon {
  color: #333 !important;
}

.select-cat :nth-child(1) {
  color: black;
  font-weight: bold;
}

.linkfile {
  color: var(--color-principal);
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  text-transform: none !important;
}

.filecontainerlink {
  text-decoration: none;
  list-style: none;
}

.hprice {
  color: #a6a6a6;
  font-weight: normal;
}

.details .img-container {
  max-width: 400px;
  width: 100%;
  margin: 20px;
  height: 450px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.details .img-container:hover {
  background-size: 150%;
  border: 1px solid #ddd;
  cursor: zoom-in;
}

.img-rows {
  max-width: 400px;
  width: 100%;
  display: block;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* .box-details button,
.box-details .cart{
 
  outline: none;
  background: #54A59E;
  cursor: pointer;
  color: white;
  margin-right: 5px;
  margin-top: 10px;
} */

.box-details .cart {
  padding: 10px 25px;
  text-transform: none;
  letter-spacing: 2px;
  display: inline-block;
  border-radius: 8px;
  box-shadow: inset 1px black;
}

.counter {
  color: black !important;
  font-size: 20px !important;
  background-color: transparent !important;
}

.level {
  border-color: tomato;
  background-color: tomato;
}

.counter-co {
  margin-top: 10px;
}

.button-counter {
  background-color: var(--color-principal) !important;
  color: white !important;
  font-weight: bold;
  font-size: 17px;
  padding: 4px;
  width: 40px;
  height: 30px;
  border-color: transparent !important;
  margin-right: 0px !important;
  vertical-align: top;
  line-height: 1;
}

.number {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  text-align: center;
  top: 0;
  bottom: 0;
  width: 60px !important;
  height: 30px;
  margin-right: 5px;
  margin-left: 5px;
  color: black;
  border-width: thin;
}

.etiqueta-combo {
  color: var(--color-principal);
  font-size: 15px;
  font-weight: bold;
  border: var(--color-principal) 2px solid;
  height: 30px;
  padding: 2px;
  border-radius: 0px 0px 0px 0px;
}

.botton-detail {
  color: white !important;
  margin: 0 !important;
  opacity: 1 !important;
}

.etiqueta-text {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  color: var(--color-principal);
  font-size: 15px !important;
  font-weight: bold !important;
}

.titulo {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  color: var(--color-principal);
  font-size: 20px !important;
  font-weight: bold !important;
}

.etiqueta {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  color: black;
  font-size: 15px !important;
  font-weight: bold !important;
}

.keytext {
  color: var(--color-principal);
  font-size: 15px !important;
  font-weight: bold !important;
  margin-right: 5px !important;
}

.valtext {
  font-size: 15px !important;
  color: #a6a6a6;
  font-weight: bold !important;
}

.combo {
  margin-top: 10px;
  width: 100px;
  display: flex;
  padding: 5px;
  height: 30px;
  color: white;
  border: var(--color-principal) 2px solid;
  background-color: var(--color-principal);
  border-radius: 0px 5px 0px 0px;
}

.combo-cont {
  display: flex;
  margin-top: 10px;
}

.carrito {
  background-color: var(--color-principal);
  padding: 10px;
  color: white;
  text-transform: none !important;
  border-radius: 5px;
  margin-top: 20px;
}

.btn-cont {
  width: 150px;
}

.area {
  border: var(--color-principal) 2px solid;
  width: 100%;
  height: 200px;
  padding: 5px;
  font-size: 18px;
  color: black;
}

.tabs {
  display: inline-table;
  background-color: white;
  max-width: 1000px;
  width: 100%;
  height: 200px;
}

.tab-header {
  width: 100%;
}

.tab-chl {
  background-color: white;
  padding: 10px;
  width: 50%;

  text-align: center;
  cursor: pointer;
  border: solid 1px #ccc;
  font-weight: bold;
  font-size: 18px;
}

.react-tabs__tab--selected {
  background-color: #e7e6e6;
}

.react-tabs__tab-panel {
  margin: 20px;
}

/* * {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100%;
  margin: auto;
  box-shadow: 0 2px 6px #555;
  padding: 0 15px;
}

/* -------------------Header----------------- */

/* header{
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
} */

a {
  text-decoration: none;
  text-transform: uppercase;
  color: #555;
  letter-spacing: 2px;
}

a:hover {
  color: var(--color-principal);
}

ul li {
  text-transform: none;
  list-style: none;
  display: inline-block;
  padding: 0 15px;
}

.menu {
  display: none;
}

.logo {
  width: 100px;
}

.cart-icon {
  width: 20px;
  color: orange;
  position: relative;
  margin-right: 15px;
}

.cart-icon span {
  background: crimson;
  border-radius: 20px;
  color: white;
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 10px;
  padding: 5px;
}

/* ---------------Products--------------- */

.products {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.tittle {
  background-color: transparent;

  width: 100%;
  margin-right: 90%;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
}

.products .card {
  max-width: 250px;
  width: 100%;
  overflow: hidden;
  height: 570px;
  box-shadow: 0 0 5px #ccc;
  padding: 15px;
  margin: 15px;
  cursor: pointer;
}

.card img {
  width: 100%;
  height: 100%;
  max-height: 340px;
  display: block;
  object-fit: cover;
}

.card .box {
  margin: 15px 0;
}

.card .box h3 {
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card .box p {
  margin: 10px 0;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  height: 70px;
}

.card .box h4 {
  color: crimson;
}

.card .box button {
  width: 100%;
  border: none;
  outline: none;
  background: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  margin: 10px 0;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* -----------------------Details--------------- */

.details {
  margin-top: 0px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 40px;
}

.details .img-container {
  max-width: 400px;
  width: 100%;
  margin: 20px;
  height: 450px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.details .img-container:hover {
  background-size: 150%;
  border: 1px solid #ddd;
  cursor: zoom-in;
}

.box-details {
  max-width: 500px;
  width: 100%;
  margin: 20px;
}

.box-details .title {
  text-transform: none;
  color: var(--color-principal);
  letter-spacing: 2px;
  font-size: 2rem;
  font-weight: bolder;
}

.box-details .price {
  margin-top: 3px;
  text-transform: none;
  color: black;
  font-size: 20px;
  font-weight: bold !important;
}

.key-val {
  display: flex;
  padding-top: 5px;
}

.key-val2 {
  display: flex;
}

.box-details .sku {
  margin-top: 5px;
  letter-spacing: 2px;
  font-size: 15px;
  color: #a6a6a6;
  font-weight: bold !important;
}

/* .box-details button,
.box-details .cart{
  border: 1px solid #555;
  outline: none;
  background: #333;
  cursor: pointer;
  color: white;
  margin-right: 5px;
  margin-top: 10px;
} */

.colors button,
.sizes button {
  width: 35px;
  height: 35px;
}

.box-details p {
  line-height: 1.5;
  margin: 10px 0;
  opacity: 0.8;
}

.bpr {
  margin-left: 91%;
  margin-top: -4%;
}

.b {
  margin-left: 6%;
}

.comenta {
  margin-left: 2%;
}

.clist {
  margin-left: 0%;
}

.plist {
  margin-left: -6%;
}

.pre {
  margin-left: 100%;
}

.box-details h3 {
  color: rgb(24, 22, 22);
}

.box-details .thumb {
  display: flex;
  cursor: pointer;
}

.box-details .thumb img {
  width: 50px;
  height: 45px;
  display: block;
  object-fit: cover;
  margin-right: 20px;
  overflow: hidden;
}

.box-details .cart {
  padding: 10px 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: inline-block;
}

/* @media (max-width: 700px){
  header{
    justify-content: space-between;
  }
  ul{
    position: fixed;
    top:0;
    left: -100%;
    width: 100%;
    height: 100vh;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    opacity: 0.98;
    z-index: 99;
    transition: 0.5s ease-in;
  }
  .menu{
    display: block;
    cursor: pointer;
  }
  ul li .menu{
    position: absolute;
    top: 20px;
    right: 20px;
  }
} */
@media screen and (max-width: 500px) {
  .tittle {
    background-color: transparent;

    width: 100%;
    margin-right: 60%;
    display: flex;
    position: -webkit-sticky;
    position: sticky;
  }

  .tab-chl {
    padding: 10px;
    width: 90%;
    margin-left: 5%;
    text-align: center;
    cursor: pointer;
    border: solid 1px #ccc;
    font-weight: bold;
    font-size: 18px;
  }

  .t {
    margin-left: 0%;
    width: 100%;
    text-align: justify;
  }

  .clist {
    margin-left: 0%;
  }
}

@media (max-width: 500px) {
  .tittle {
    background-color: transparent;

    width: 100%;
    margin-right: 60%;
    display: flex;
    position: -webkit-sticky;
    position: sticky;
  }

  .box-details .thumb img {
    width: 40px;
    height: 35px;
  }

  .details .img-container {
    height: 350px;
  }
}

/* ****** Cart ********************** */

.cart {
  position: relative;
  border: 1px solid #ccc;
  transform: scaleY(0.98);
}

.amount span {
  color: crimson;
  padding: 0 20px;
}

.amount .count {
  width: 40px;
  height: 30px;
  border: 1px solid #555;
  outline: none;
  background: #555;
  cursor: pointer;
}

.delete {
  position: absolute;
  top: 0;
  right: 5px;
  color: crimson;
  cursor: pointer;
  font-weight: 900;
}

.total {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total a {
  background: #333;
  color: white;
  padding: 10px 25px;
}

.total h3 {
  margin-right: 50px;
  color: crimson;
}

.tabs1 {
  margin-left: 5%;
  margin-right: 5%;
}

@media screen and (min-width: 100px) {
  .question {
    width: 70%;
    margin-top: 10%;
    /* margin: 0; */
    margin-left: 20%;
  }

  .colec {
    margin-left: 20%;
  }
}

@media screen and (min-width: 1336px) {
  .tabs1 {
    width: 70%;
    /* display: flex; */
    justify-content: left;
    margin-block-end: 5px;
    padding-inline-end: 10px;
    position: relative;
    height: 28px;
    margin-left: 5%;
    font-size: 22px;
    font-weight: bold;
    margin-top: 0%;
  }

  .colec {
    margin-left: 45%;
  }

  .question {
    width: 60%;
    margin-top: 30%;
    /* margin: 0; */
    margin-left: 20%;
  }

  .tabs li {
    margin: 0;
    padding: 0 25px;
    list-style-type: none;
  }

  .titleColeccion1 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family:
      Open Sans,
      sans-serif;
    letter-spacing: 1px;
    color: rgb(61, 59, 59);
  }

  .titleColeccion2 {
    text-align: center;
    font-size: 15px;
  }
}